import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { EmojiAddIcon } from '../icons/EmojiAddIcon'
import { EmojiType, ReactionGetType, ReactionTargetType } from '../types/models/Reaction'
import Styles from "../styles/ReactionList.module.scss"
import clsx from "clsx"
import { addReactionComment, removeReactionComment } from '../apis/reaction'
import { useEmojiPickerModal } from '../hooks/useEmojiPickerModal'
import { ToastContext } from '../context/ToastContext'
import { ToastPosition, ToastType } from '../types/models/Toast'
import { useSelector } from 'react-redux'
import { AppState } from '../state/app'
import { useIsLoggedIn } from '../hooks/useIsLoggedIn'


type Props = {
    originalReactionList: ReactionGetType[]
    targetPk: string
    targetModel: ReactionTargetType
    setHideReactionList: ( b: boolean ) => void
}

export const ReactionList: FC<Props> = ( { originalReactionList, targetPk, targetModel, setHideReactionList } ) => {

    const [ reactionList, setReactionList ] = useState( originalReactionList );
    const { openEmojiModal, ModalComponent: EmojiModalComponent } = useEmojiPickerModal( { addReaction } )
    const { userUuid } = useSelector( ( state: AppState ) => state ).app
    const isLoggedIn = useIsLoggedIn()
    const { addToast } = useContext( ToastContext )

    useEffect( () => {
        reactionList.length === 0 ?
            setHideReactionList( true ) :
            setHideReactionList( false )
    }, [ reactionList ] )

    async function addReaction ( emoji: EmojiType ) {
        if ( !isLoggedIn || !userUuid ) {
            addToast( {
                position: ToastPosition.CENTER,
                type: ToastType.WARN,
                text: "ログインしていないとリアクションできません"
            } )
            return
        }
        const thisEmojiAlreadyAdded =
            userUuid && reactionList.find( reaction => reaction.emoji.emoji_name === emoji.emoji_name )?.authors.map( author => author.pk ).includes( userUuid )
        if ( thisEmojiAlreadyAdded ) return

        const prevReactionList = reactionList.slice()
        const newReactionList = addReactionWithPk( reactionList, emoji, "" )
        if ( !newReactionList ) return
        setReactionList( newReactionList )
        try {
            const { data } = await addReactionComment( {
                target_model: targetModel,
                target_uuid: targetPk,
                emoji_unicode: emoji.emoji_unicode,
                emoji_name: emoji.emoji_name,
                text: emoji.text,
            } )
            const validReactionList = addReactionWithPk( prevReactionList, emoji, data.reaction_pk )
            if ( !validReactionList ) return
            setReactionList( validReactionList )
        }
        catch ( e ) {
            console.log( "e", e )
            setReactionList( prevReactionList )
        }
    }

    const removeReaction = async ( reactionPk: string ) => {
        // if ( addingOrRemovingReaction ) return
        if ( !reactionPk ) return
        const prevReactionList = reactionList.slice()
        const newReactionList = removeReactionForNow( reactionList, reactionPk )
        setReactionList( newReactionList )
        try {
            await removeReactionComment( reactionPk )
        }
        catch ( e ) {
            console.log( "e", e )
            setReactionList( prevReactionList )
        }
    }

    const addReactionWithPk = ( reactionList: ReactionGetType[], emoji: EmojiType, reactionPk: string ) => {
        if ( !userUuid ) return
        const thisEmojiAlreadyExists = reactionList.map( reaction => reaction.emoji.emoji_name ).includes( emoji.emoji_name )
        let newReactionList: ReactionGetType[]
        if ( thisEmojiAlreadyExists ) {
            newReactionList = reactionList.map( reaction =>
                reaction.emoji.emoji_name === emoji.emoji_name ?
                    { ...reaction, authors: [ ...reaction.authors, { name: "name", pk: userUuid, reaction_pk: reactionPk } ] } :
                    reaction
            )
        }
        else {
            newReactionList = [ ...reactionList, { emoji, authors: [ { name: "name", pk: userUuid, reaction_pk: reactionPk } ] } ]
        }
        return newReactionList.slice()
    }

    const removeReactionForNow = ( reactionList: ReactionGetType[], reactionPk: string ) => {
        console.log( reactionList, reactionPk )
        const newReactionList: ReactionGetType[] = []
        reactionList.forEach( reaction => {
            if ( reaction.authors.map( author => author.reaction_pk ).includes( reactionPk ) ) {
                2 <= reaction.authors.length && newReactionList.push( {
                    ...reaction,
                    authors: reaction.authors.filter( author => author.reaction_pk !== reactionPk )
                } )

            }
            else {
                newReactionList.push( reaction )
            }
        } )
        return newReactionList.slice()
    }

    return (
        <div className={ Styles.container }>
            <ul className={ Styles.list }
                onMouseDown={ ( e ) => { e.preventDefault(); e.stopPropagation(); e.nativeEvent.stopImmediatePropagation() } }
                onTouchStart={ ( e ) => { e.preventDefault(); e.stopPropagation(); e.nativeEvent.stopImmediatePropagation() } }>
                { reactionList.map( reaction =>
                    <ReactionItem
                        key={ reaction.emoji.emoji_name }
                        userUuid={ userUuid }
                        reaction={ reaction }
                        addReaction={ addReaction }
                        removeReaction={ removeReaction } />
                ) }
            </ul>
            <div className={ Styles.emoji_add_icon_container }>
                <EmojiAddIcon onClick={ openEmojiModal } className={ Styles.emoji_add_icon } />
            </div>
            { EmojiModalComponent }
        </div>
    )
}

type ItemProps = {
    reaction: ReactionGetType
    userUuid?: string
    removeReaction: ( reactionPk: string ) => void
    addReaction: ( emoji: EmojiType ) => void
}

const ReactionItem: FC<ItemProps> = ( { reaction, userUuid, removeReaction, addReaction } ) => {


    const getInfo = useCallback( (): { alreadyAdded: boolean, renderUserNames: string, ownReactionPk: string } => {
        let alreadyAdded
        let otherMembers
        let renderUserNames
        let ownReactionPk
        if ( userUuid ) {
            alreadyAdded = reaction.authors.map( author => author.pk ).includes( userUuid )
            otherMembers = reaction.authors.filter( author => author.pk !== userUuid ).map( author => author.name ).join( "、 " )
            renderUserNames = alreadyAdded ?
                `あなた${ otherMembers.length ? `と${ otherMembers }` : "" }は` :
                `${ otherMembers }が`
            ownReactionPk = userUuid ? reaction.authors.filter( author => author.pk === userUuid )[ 0 ]?.reaction_pk : ""
        }
        else {
            alreadyAdded = false
            otherMembers = reaction.authors.map( author => author.name ).join( "、 " )
            renderUserNames = `${ otherMembers }が`
            ownReactionPk = userUuid ? reaction.authors.filter( author => author.pk === userUuid )[ 0 ]?.reaction_pk : ""
        }
        return {
            alreadyAdded, renderUserNames, ownReactionPk
        }
    }, [ reaction, userUuid ] )
    const { alreadyAdded, renderUserNames, ownReactionPk } = getInfo()

    return (
        <li className={ Styles.item_container }>
            <div className={ clsx( Styles.tooltip ) }>
                <p className={ Styles.description }>{ renderUserNames }</p>
                <p className={ Styles.tooltip_emoji }>{ reaction.emoji.text }</p>
                <p className={ Styles.description }>でリアクションしました</p>
                <p className={ Styles.description }>(クリックで取り消せます)</p>
            </div>
            <div
                className={ clsx( Styles.item, alreadyAdded && Styles.emoji_added_item ) }
                onClick={ () => alreadyAdded ? removeReaction( ownReactionPk ) : addReaction( reaction.emoji ) }>
                <span className={ Styles.emoji }>{ reaction.emoji.text }</span>
                <span className={ Styles.emoji_count }>{ reaction.authors.length }</span>
            </div>
        </li>
    )
}
