import React, { FC } from 'react';

const iOSApplicationURL = process.env.iOSApplicationURL

type AppOpenOrMoveToStoreButtonProps = {
    appDeepLinkURL: string,
    style: object
}

const AppOpenOrMoveToStoreButton: FC<AppOpenOrMoveToStoreButtonProps> = ( {
    appDeepLinkURL,
    style
} ) => {

    return (
        <div
            onClick={ () => {
                window.location.href = appDeepLinkURL
                // setTimeout( () => { window.location.href = iOSApplicationURL }, 3000 )
            } }
            style={ { ...Styles.container, ...style } }>
            <span style={ Styles.text }>
                アプリで開く
            </span>
        </div>
    )
}

const Styles = {
    container: {
        height: 36,
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        backgroundColor: "rgb(255, 84, 28)",
        background: "linear-gradient( to bottom, rgb( 255, 24, 12 ), rgb(255, 84, 28) )",
        borderRadius: 24,
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.27)"
    } as React.CSSProperties,
    text: {
        padding: "8px 16px",
        color: "#fff",
        lineHeight: 0,
        fontSize: "1rem",
        fontWeight: 700,
        wordBreak: "keep-all"
    } as React.CSSProperties
}

export default AppOpenOrMoveToStoreButton
