import React, { CSSProperties } from 'react';
import Styles from "../../styles/TagAddButton.module.scss";
import { PlusIcon } from '../../icons/PlusIcon';
import { TagIcon } from '../../icons/TagIcon';

type Props = {
    style: CSSProperties,
    onClick: () => void
}

const TagAddButton = ( {
    style,
    onClick
}: Props ) =>
    <div
        className={ `${ Styles.container }` }
        style={ style }
        onClick={ () => onClick() }>
        <TagIcon className={ Styles.tag_icon } />
        <PlusIcon className={ Styles.add_icon } />
    </div>

export default TagAddButton
