import { useEffect } from 'react'
import { LocalStorageWrapper } from '../utils/LocalStorageWrapper'
import { SavedCommentType } from '../types/models/Comment';

type useSaveCommentPerPostProps = {
    postID: string
    setText: ( text: string ) => void
}

export const useApplySaveComment = ( { postID, setText }: useSaveCommentPerPostProps ) => {

    useEffect( () => {
        if ( !postID ) return
        const savedComment = getSavedComment()
        applySavedComment( savedComment )
    }, [ postID ] )


    const getSavedComment = () => {
        const savedCommentList: SavedCommentType[] = LocalStorageWrapper.getItem( "savedCommentList" ) || []
        const savedComment = savedCommentList.filter( comment => comment.postID === postID )[ 0 ]
        return savedComment
    }

    const applySavedComment = ( savedComment: SavedCommentType ) => {
        savedComment?
            setText( savedComment.text ) :
            setText( "" )
    }
}
