import React, { FC } from "react"
import Styles from "../../styles/CommentFormButton.module.scss";
import { connect } from "react-redux";
import { CommentIcon } from "../../icons/CommentIcon";

type CommentFormButtonProps = {
    onClick: () => void,
    commentFormVisible: boolean
}

const CommentFormButton: FC<CommentFormButtonProps> = ( {
    onClick,
    commentFormVisible
} ) =>
    <button
        style={ commentFormVisible ? { display: "none" } : {} }
        className={ `${ Styles.container }` }
        id="comment_form_button"
        onClick={ () => onClick() }>
        <CommentIcon className={ Styles.icon }/>
    </button>
const mapStateToProps = ( state: any ) => ( {
    commentFormVisible: state.app.commentFormVisible
} )

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( CommentFormButton )
