import React, { useState } from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { EmojiData, Picker } from 'emoji-mart'
import { EMOJI_I18n } from '../constant/const'
import { EmojiType, isCustomEmoji } from '../types/models/Reaction'
import ReactModal from 'react-modal'
import { CloseIcon } from '../icons/CloseIcon'

ReactModal.setAppElement( "#___gatsby" );

const modalStyle: ReactModal.Styles = {
    overlay: {
        position: "fixed",
        // top:300,
        // left:300,
        bottom: 0,
        right: 0,
        // top:"auto",
        // left:"auto",
        backgroundColor: "rgba(255,255,255,0.4)",
        zIndex: 10,
        // pointerEvents:"none"
    },
    // content: {
    //     position: "absolute",
    //     top: "5rem",
    //     left: "5rem",
    //     right: "5rem",
    //     bottom: "5rem",
    //     display:"flex",
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     backgroundColor: "rgba(0,0,0,0)",
    //     border: "none",
    //     borderRadius: "1rem",
    //     padding: "1.5rem"
    // },
    content: {
        position: "absolute",
        top: "unset",
        left: "unset",
        right: "8px",
        bottom: "8px",
        display: "inline-flex",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: 0,
        // alignItems: 'center',
        // justifyContent: 'center',
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
        // borderRadius: "1rem",
        // padding: "1.5rem"
    }
};

type Props = {
    addReaction: ( emoji: EmojiType ) => void
}

export const useEmojiPickerModal = ( { addReaction }: Props ) => {

    const [ modalVisible, setModalVisible ] = useState( false );

    const onEmojiSelect = ( emoji: EmojiData ) => {
        if ( isCustomEmoji( emoji ) ) return
        setModalVisible( false )
        addReaction( {
            emoji_unicode: emoji.unified,
            emoji_name: emoji.id,
            text: emoji.native,
        } )
    }

    return {
        openEmojiModal: () => setModalVisible( true ),
        ModalComponent:
            <ReactModal
                isOpen={ modalVisible }
                onRequestClose={ () => setModalVisible( false ) }
                shouldCloseOnOverlayClick
                shouldCloseOnEsc
                style={ modalStyle }
                contentLabel="Emji Picker">
                <div style={ { textAlign: "center" } }>
                    <div style={ { display: "inline-flex", justifyContent: "flex-end", flexDirection: "column", position: "relative" } }>
                        <Picker onSelect={ onEmojiSelect } showSkinTones={ false } showPreview={ false } i18n={ EMOJI_I18n } />
                    </div>
                </div>
            </ReactModal>
    }
}
