import { useState, useEffect, useCallback } from "react"

function useLongPress ( callback: () => void, ms: number ) {

    const [ startLongPress, setStartLongPress ] = useState( false );

    useEffect( () => {
        let timerId: NodeJS.Timeout | undefined = undefined;
        if ( startLongPress ) {
            timerId = setTimeout( callback, ms );
        }
        else {
            timerId && clearTimeout( timerId );
        }
        return () => timerId && clearTimeout( timerId );
    }, [ startLongPress ] );

    const start = useCallback( () => {
        setStartLongPress( true );
    }, [] );

    const stop = useCallback( () => {
        setStartLongPress( false );
    }, [] );

    return {
        onMouseDown: stop,
        onTouchStart: start,
        onTouchEnd: stop,
    };
}

export default useLongPress
