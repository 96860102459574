import { LocalStorageWrapper } from '../utils/LocalStorageWrapper'
import { SavedCommentType } from '../types/models/Comment';
import { COMMENT_SAVE_LIMIT } from '../constant/const';


export function saveCommentText ( { text, postID }: { text: string, postID: string } ) {
    const savedCommentList: SavedCommentType[] = LocalStorageWrapper.getItem( "savedCommentList" ) || []
    const newComment = { postID, text, timestamp: new Date().getTime() }
    let newSavedCommentList
    if ( savedCommentList.map( comment => comment.postID ).includes( postID ) ) {
        // もともとあったら変更
        newSavedCommentList = savedCommentList.map( savedComment =>
            savedComment.postID === postID ?
                newComment :
                savedComment
        )
    }
    else {
        // もともとなかったら追加
        newSavedCommentList = [ ...savedCommentList, newComment ]
    }
    newSavedCommentList = newSavedCommentList.filter( comment => comment.timestamp > new Date().getTime() - COMMENT_SAVE_LIMIT )
    LocalStorageWrapper.setItem( "savedCommentList", newSavedCommentList )
}

export function removeCommentText ( { postID }: { postID: string } ) {
    const savedCommentList: SavedCommentType[] = LocalStorageWrapper.getItem( "savedCommentList" ) || []
    const newSavedCommentList = savedCommentList
        .filter( comment =>
            comment.timestamp > new Date().getTime() - COMMENT_SAVE_LIMIT )
        .filter( comment =>
            comment.postID !== postID )
    LocalStorageWrapper.setItem( "savedCommentList", newSavedCommentList )
}
