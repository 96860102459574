export const SessionStorageWrapper = {
    getItem: ( key: string ): any => {
        const stringifiedData = sessionStorage.getItem( key )
        return stringifiedData ?
            JSON.parse( stringifiedData ) :
            stringifiedData
    },
    setItem: ( key: string, obj: any ) =>
        sessionStorage.setItem( key, JSON.stringify( obj ) )
}
