import React, { FC, CSSProperties } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { faHeart as faHeartFill } from '@fortawesome/free-solid-svg-icons'
import { HiOutlineHeart } from "react-icons/hi"


type Props = {
    style?: CSSProperties,
    filled?:boolean
    className?: string
    onClick?: () => void
}

export const HeartIcon: FC<Props> = ( { filled ,onClick, className, style } ) =>
    // <HiOutlineHeart className={ className } style={ style } onClick={ () => onClick && onClick() } />
    filled?
    <FontAwesomeIcon
        icon={faHeartFill as IconProp}
        className={ className } style={ style } onClick={ () => onClick && onClick() } />:
    <FontAwesomeIcon
        icon={ faHeart as IconProp }
        className={ className } style={ style } onClick={ () => onClick && onClick() } />
