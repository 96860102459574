import React, { FC, CSSProperties } from 'react'
import { AiOutlineShareAlt } from "react-icons/ai"

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?:()=>void
}

export const ShareIcon: FC<Props> = ( { onClick, className, style } ) =>
    <AiOutlineShareAlt className={ className } style={ style } onClick={ () => onClick && onClick()}/>
