import React, { FC, useState } from 'react'
import Styles from "../styles/MyComment.module.scss"
import { CommentType, isImageComment, isVideoComment } from '../types/models/Comment'
import moment from "moment"
import clsx from "clsx"
import { OutputAuthorType, UserType } from '../types/models/User'
import { ToComment } from './ToComment'
import MyImage from "./ImageComponent";
import { VideoSelectActionType } from '../types/WebViewVideoAction'
import { VideoType } from '../types/models/Media/Video'
import { ReactionList } from './ReactionList'
import useLongPress from '../hooks/useLongPress'
import { useLocalSettings } from '../hooks/useLocalSettings'
import { useIsBigScreen } from '../hooks/useIsBigScreen'
import LinkIncludedText from './LinkIncludedText'
import { VideoModal } from './VideoModal'
import { VideoThumbnail } from './VideoThumbnail'

type Props = {
    comment: CommentType
    toCommentAuthor?: OutputAuthorType
    hasFile: boolean
    isSerialComment: boolean
    scrollToComment: ( { commentPk, blink }: { commentPk: string, blink: boolean } ) => void
    handleVideoSelectSwitch: ( action: VideoSelectActionType, video: VideoType ) => void
    showThumbnailInitially: boolean
    showVideo: () => void
    videoIsSelected: boolean
    showActionSheet: () => void
    teamMemberList: UserType[]
}

export const MyComment: FC<Props> = ( { comment, toCommentAuthor, hasFile, scrollToComment, handleVideoSelectSwitch,
    showThumbnailInitially,
    showVideo, isSerialComment, teamMemberList,
    videoIsSelected, showActionSheet } ) => {

    const [ hideReactionList, setHideReactionList ] = useState( comment.reactions.length === 0 );
    const [ videoModalVisible, setVideoModalVisible ] = useState( false );
    const showActionSheetOnLongPress = useLongPress( showActionSheet, 1000 )
    const localSettings = useLocalSettings( {} )
    const isBigScreen = useIsBigScreen()

    const renderDateTime = () =>
        <div className={ Styles.date_container }>
            <span className={ Styles.date }>
                { `${ moment( comment.created_at ).format( 'Y/M/D/ H:mm' ) }` }
            </span>
        </div>

    return (
        <div className={ Styles.container_wrapper } style={ localSettings.commentDateTimeVisible ? { marginTop: 16 } : {} }>
            {!isBigScreen &&
                localSettings.commentDateTimeVisible &&
                renderDateTime()
            }
            <div
                className={ clsx( Styles.container, hasFile && Styles.file_comment_container ) }>
                <div className={ Styles.comment_with_info }>
                    <div
                        id={ `comment-${ comment.pk }` }
                        { ...showActionSheetOnLongPress }
                        onContextMenu={ ( e ) => { e.preventDefault(); showActionSheet() } }
                        className={ Styles.comment_main }>
                        { comment.to_comment_object && toCommentAuthor &&
                            <ToComment
                                onMyComment={ true }
                                toComment={ comment.to_comment_object }
                                toCommentAuthor={ toCommentAuthor }
                                scrollToComment={ scrollToComment } /> }
                        <div className={ Styles.file_and_text }>
                            { isVideoComment( comment ) &&
                                <div className={ Styles.file_wrapper }>
                                    <VideoThumbnail
                                        onClick={ () => setVideoModalVisible( true ) }
                                        isSelected={ videoIsSelected }
                                        thumbnail={ comment.thumbnail }
                                        videoDuration={ comment.video_duration }
                                        file={ comment.file } />
                                    <VideoModal
                                        modalVisible={ videoModalVisible }
                                        setModalVisible={ setVideoModalVisible }
                                        file={ comment.file }
                                        thumbnail={ comment.thumbnail }
                                        handleVideoSelectSwitch={ handleVideoSelectSwitch }
                                        videoIsSelected={ videoIsSelected } />
                                </div> }
                            { isImageComment( comment ) &&
                                <div className={ Styles.file_wrapper }>
                                    <MyImage
                                        file={ comment.file } />
                                </div> }
                            <p className={ Styles.comment_text }>
                                <LinkIncludedText
                                    teamMemberList={ teamMemberList }
                                    textStyle={ { color: "#fff" } }>
                                    { comment.text }
                                </LinkIncludedText></p>
                        </div>
                        { isBigScreen && localSettings.commentDateTimeVisible &&
                            renderDateTime() }
                    </div>
                    <div className={ clsx( Styles.reaction_container, hideReactionList && Styles.hidden_reaction_container ) }>
                        <ReactionList
                            originalReactionList={ comment.reactions }
                            targetModel="Comment"
                            targetPk={ comment.pk }
                            setHideReactionList={ setHideReactionList } />
                    </div>
                </div>
            </div>

        </div>
    )
}
