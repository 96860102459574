import { RefObject, useContext, useEffect, useRef, useState } from "react"
import { useInterval } from "./useInterval";
import { ToastContext } from '../context/ToastContext';
import { ToastPosition, ToastSize, ToastType } from "../types/models/Toast";
import { COMMENT_SAVE_INTERVAL } from "../constant/const";
import { saveCommentText } from "../utils/commentSaveManager";


type useSaveCommentProps = {
    postID: string
    textRef: RefObject<string>
}

export const useSaveComment = ( { postID, textRef }: useSaveCommentProps ) => {

    const { addToast } = useContext( ToastContext )
    const postIDRef = useRef( postID )
    useEffect( () => {
        postIDRef.current = postID
    }, [ postID ] )
    const [ prevText, setPrevText ] = useState<string>( textRef.current || "" );
    const prevTextRef = useRef<string>( prevText )
    useEffect( () => {
        prevTextRef.current = prevText
    }, [ prevText ] )

    useEffect( () => {
        if ( postID ) {
            stopInterval()
            startInterval()
        }
        else {
            stopInterval()
        }
    }, [ postID ] )

    const { startInterval, stopInterval } = useInterval( { func: saveComment, interval: COMMENT_SAVE_INTERVAL } )

    function saveComment () {
        // console.log( prevTextRef.current, textRef.current)
        if ( prevTextRef.current === textRef.current ) return
        saveCommentText( {
            text: textRef.current || "",
            postID: postIDRef.current
        } )
        setPrevText( textRef.current || "" )
        addToast( {
            text: "端末にコメントを自動保存しました",
            type: ToastType.INFO,
            position: ToastPosition.BOTTOM,
            size: ToastSize.SMALL
        } )
    }
}
