import React, { useState, useRef, useContext, FC } from "react"
import Styles from "../styles/CommentItem.module.scss";
import ActionSheet from "../components/ActionSheet";
import UserAvatar from "../components/UserAvatar";
import Modal from "../components/Modal";
import useDetectDevice from "../hooks/useDetectDevice";
import smoothscroll from 'smoothscroll-polyfill';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { navigate } from "gatsby";
import { ToastContext } from '../context/ToastContext';
import { CommentType, ArrangedCommentType, ReplyTargetType, ToCommentObjectType } from "../types/models/Comment";
import { UserType } from "../types/models/User";
import { ActionSheetMenuType } from "../types/models/ActionSheetMenu";
import { VideoSelectActionType } from "../types/WebViewVideoAction";
import { VideoType } from "../types/models/Media/Video";
import client from "../apis/client";
import { OthersComment } from "./OthersComment";
import { MyComment } from "./MyComment";
import { detectDevice } from "../functions/detectDevice";



if ( ( typeof window !== 'undefined' ) && ( typeof document !== 'undefined' ) ) {
    smoothscroll.polyfill();
}

type Props = {
    comment: ArrangedCommentType,
    handleVideoSelectSwitch: ( action: VideoSelectActionType, video: VideoType ) => void,
    videoIsSelected: boolean,
    commentList: CommentType[],
    setReplyTarget: ( replyTarget: ReplyTargetType | null ) => void,
    removeCommentFromScreen: ( pk: string ) => void,
    teamMemberList: UserType[]
}

const CommentItem: FC<Props> = ( {
    comment,
    handleVideoSelectSwitch,
    videoIsSelected,
    commentList,
    setReplyTarget,
    removeCommentFromScreen,
    teamMemberList
} ) => {

    const { addToast } = useContext( ToastContext );
    const [ actionSheetVisible, setActionSheetVisible ] = useState( false )
    const [ imageModalVisible, setImageModalVisible ] = useState( false )
    const [ showThumbnailInitially, setShowThumbnailInitially ] = useState( true )
    const CopyToClipboardRef = useRef<HTMLSpanElement>( null )

    // const ImageDownloadRef = useRef(null)

    const { isPC } = useDetectDevice()

    const scrollToComment = ( {
        commentPk,
        blink = false
    }: { commentPk: string, blink?: boolean } ) => {
        const toCommentObject = document.getElementById( `comment-${ commentPk }` )
        const toCommentY = toCommentObject ?
            toCommentObject.getBoundingClientRect().top :
            0
        const toCommentHeight = toCommentObject ?
            toCommentObject.getBoundingClientRect().height :
            0
        const doc = document.getElementsByTagName( "body" )[ 0 ]
        const currentScroll = window.pageYOffset
        const { isAndroid } = detectDevice()
        const scrollObject = isAndroid ?
            // doc :
            window :
            window
        const scrollAmount = isAndroid ?
            currentScroll + toCommentY - doc.getBoundingClientRect().height / 2 + toCommentHeight / 2 :
            // currentScroll + toCommentY - window.innerHeight / 2 + toCommentHeight / 2:
            currentScroll + toCommentY - window.innerHeight / 2 + toCommentHeight / 2
        scrollObject.scrollTo( {
            top: scrollAmount,
            behavior: "smooth"
        } );
        if ( !blink ) return
        toCommentObject && toCommentObject.classList.add( "blink" )
        setTimeout( () => {
            toCommentObject && toCommentObject.classList.remove( "blink" )
        }, 3000 )
    }

    const deleteComment = async () => {
        if ( !window.confirm( "このコメントを削除しますか？" ) ) {
            return
        }
        await client.delete( `/api/comment/${ comment.pk }/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( () => removeCommentFromScreen( comment.pk ) )
            .catch( error => console.log( error ) )
    }

    const showImage = () => {
        setImageModalVisible( true )
    }

    const showVideo = () => {
        scrollToComment( { commentPk: comment.pk } )
        setShowThumbnailInitially( false )
    }

    const renderToCommentAuthorAvator = ( toComment: ToCommentObjectType ) => {
        const toCommentAuthor = commentList.filter( comment => comment.pk === toComment.pk )[ 0 ].output_author
        return toCommentAuthor.pk ?
            <UserAvatar
                userID={ toCommentAuthor.pk }
                userName={ toCommentAuthor.name }
                size={ 24 }
                avatarSize={ 12 }
                style={ { height: 24, width: 24 } }
                noTransition={ true }
                profileImageURL={ toCommentAuthor.profile_image_100 } /> :
            null
    }


    const renderActionSheet = () => {
        const menuList = [
            { name: "返信", func: () => setReplyTarget( { ...comment } ) },
            comment.file_type === 2 && { name: "画像を保存", func: () => showImage() },
            { name: "文章をコピー", func: () => CopyToClipboardRef.current && CopyToClipboardRef.current.click() },
            comment.is_mine && !comment.deleted && { name: "編集", func: () => navigate( "/comment_update", { state: { comment } } ) },
            comment.is_mine && !comment.deleted && { name: "削除", func: () => deleteComment() },
        ].filter( menu => menu )
        return (
            <ActionSheet menuList={ menuList as ActionSheetMenuType[] } visible={ actionSheetVisible } hideActionSheet={ () => setActionSheetVisible( false ) } />
        )
    }

    const renderImageModal = () =>
        <Modal visible={ true } closeModal={ () => setImageModalVisible( false ) }>
            <>
                <p style={ { color: "#fff", textAlign: "center", marginBottom: 8 } }>
                    { isPC ? "右クリックメニューから保存できます" : "長押しで保存できます" }
                </p>
                <img src={ comment.file } style={ { display: "block", margin: "auto", maxWidth: "100%", maxHeight: "70vh" } } />
            </>
        </Modal>

    const renderModalAndActionSheet = () =>
        <>
            <CopyToClipboard
                text={ comment.text }
                onCopy={ () => addToast( { text: "文章をコピーしました", type: "success", position: 'center', } ) }>
                <span style={ { display: "none" } } ref={ CopyToClipboardRef }>クリップボード</span>
            </CopyToClipboard>
            { renderActionSheet() }
            { imageModalVisible && renderImageModal() }
        </>

    const showActionSheet = () => setActionSheetVisible( true )
    const fileComment = [ 1, 2 ].includes( comment.file_type )
    const displayUserAvatar = !( comment.deleted || comment.is_mine || comment.sameAuthorAsPreviousComment )
    const toCommentAuthor = commentList.filter( com => com.pk === comment.to_comment_object?.pk )[ 0 ]?.output_author


    if ( comment.deleted ) return null
    return (
        comment.is_mine ?
            <>
                <MyComment
                    comment={ comment }
                    hasFile={ fileComment }
                    toCommentAuthor={ toCommentAuthor }
                    scrollToComment={ scrollToComment }
                    handleVideoSelectSwitch={ handleVideoSelectSwitch }
                    showThumbnailInitially={ showThumbnailInitially }
                    showVideo={ () => showVideo() }
                    videoIsSelected={ videoIsSelected }
                    showActionSheet={ showActionSheet }
                    isSerialComment={ comment.sameAuthorAsPreviousComment }
                    teamMemberList={ teamMemberList } />
                { renderModalAndActionSheet() }
            </> :
            <>
                <OthersComment
                    comment={ comment }
                    hasFile={ fileComment }
                    displayUserAvator={ displayUserAvatar }
                    toCommentAuthor={ toCommentAuthor }
                    scrollToComment={ scrollToComment }
                    handleVideoSelectSwitch={ handleVideoSelectSwitch }
                    showThumbnailInitially={ showThumbnailInitially }
                    showVideo={ () => showVideo() }
                    videoIsSelected={ videoIsSelected }
                    showActionSheet={ showActionSheet }
                    isSerialComment={ comment.sameAuthorAsPreviousComment }
                    teamMemberList={ teamMemberList } />
                { renderModalAndActionSheet() }
            </>
    )
}

export default CommentItem
