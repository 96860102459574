import React, { useState, useEffect } from 'react'
import Styles from "../styles/useRecommendPost.module.scss"
import { isPostNotUndefined, PostDetailType, PostTimeLineType } from '../types/models/Post';
import { LinkPageComponent } from '../components/LinkPageComponent';
import { SessionStorageWrapper } from '../utils/SesseionStorageWrapper';
import { linkPageObjectType } from '../components/PageLinkText';
import client from '../apis/client';

const KeyLabelList = [
    { key: "recommendTimeLinePost", label: "あなたのチーム" },
    { key: "recommendThisUserPost", label: "この人の動画" },
    { key: "recommendMyOwnPost", label: "あなたの動画" },
    { key: "recommendFollowSportsPost", label: "フォーローしているスポーツ" },
]
const labelList = KeyLabelList.map( obj => obj.key )

type recommendPostsObjectType = {
    [ key in typeof labelList[ number ] ]: PostTimeLineType | null
}

type Props = {
    post: PostTimeLineType | PostDetailType | undefined
}

export const useRecommendPost = ( { post }: Props ) => {

    const [ recommendPostsObject, setRecommendPostsObjects ] = useState<recommendPostsObjectType | null>( null );
    const [ fetching, setFetching ] = useState( false );

    useEffect( () => {
        // !recommendPost && 
        fetchRecommendPostList()
    }, [ post ] )

    const renderRecommendBox = () =>
        recommendPostsObject &&
        <div className={ Styles.box }>
            <h2 className={ Styles.h2 }>関連動画</h2>
            <div className={ Styles.post_list_container }>
                { KeyLabelList.map( item => {
                    const recommendPost = recommendPostsObject[ item.key ]
                    return (
                        recommendPost ?
                            <div className={ Styles.item_container } key={ item.key }>
                                <h3 className={ Styles.label }>{ item.label }</h3>
                                <LinkPageComponent
                                    linkPageObject={ recommendPost as unknown as linkPageObjectType }
                                    fetching={ fetching } />
                            </div> :
                            null )
                } ) }
            </div>
        </div>


    const fetchRecommendPostList = async () => {
        if ( !isPostNotUndefined( post ) ) return
        if ( fetching ) return
        const previousRecommend = SessionStorageWrapper.getItem( `post-${ post.pk }-recommend` )
        if ( previousRecommend ) {
            setRecommendPostsObjects( previousRecommend )
            return
        }
        setFetching( true )
        await client.get( `/api/post/${ post.pk }/recommend/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( response => {
                SessionStorageWrapper.setItem( `post-${ post.pk }-recommend`, response.data )
                setRecommendPostsObjects( response.data )
            } )
            .catch( error => console.log( error ) )
            .finally( () => setFetching( false ) )
    }

    return {
        renderRecommendBox
    }

}
