import React, { useState, useRef, FC } from "react"
import Styles from "../../styles/PublishRangeIcon.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUser, faGlobeAmericas, faLink } from '@fortawesome/free-solid-svg-icons'
import clsx from "clsx"
import { useClickAway } from "../../hooks/useClickAway"

const PublishRangeList = [
    { icon: faGlobeAmericas, key: "public", label: "一般公開", description: "タイムラインや検索結果にも表示され、誰でも閲覧可能です" },
    { icon: faLink, key: "limitedly_public", label: "限定公開", description: "他チームのタイムラインや検索には非表示ですが、URLを知っていれば誰でも閲覧可能です" },
    { icon: faUsers, key: "timeline", label: "チーム内", description: "チームメンバーのみ閲覧可能です" },
    { icon: faUser, key: "myown", label: "自分のみ", description: "自分のみ閲覧可能です" },
]
type Props ={
    type:string
}

export const PublishRangeIcon: FC<Props> = ( {
    type
} ) => {

    const [ rangeListVisible, setRangeListVisible ] = useState( false );
    const iconRef = useRef<HTMLSpanElement>(null)
    useClickAway( {
        clickExceptRef: iconRef,
        clickAwayFunction: () => setRangeListVisible( false ),
        executeWhenClickInside: true
    } )

    return (
        <div className={ Styles.container }>
            <span
                className={ Styles.publish_range_icon }
                ref={ iconRef }
                onClick={ () => setRangeListVisible( true ) }>
                <FontAwesomeIcon icon={ PublishRangeList.filter( range => range.key === type )[0].icon } />
            </span>
            { true &&
                <div className={ clsx( Styles.publish_range_list_container_wrapper, rangeListVisible && Styles.visible ) }>
                    <div className={ Styles.publish_range_list_container }>
                        <ul className={ Styles.publish_range_list }>
                            { PublishRangeList.map( range =>
                                <li
                                    key={ range.key }
                                    className={ clsx( Styles.publish_range_item, type === range.key && Styles.active_item ) }>
                                    <FontAwesomeIcon
                                        icon={ range.icon }
                                        className={ Styles.icon } />
                                    <div className={ Styles.detail }>
                                        <span className={ Styles.label }>{ range.label }</span>
                                        <p className={ Styles.description }>{ range.description }</p>
                                    </div>
                                </li> ) }
                        </ul>
                    </div>
                </div> }
        </div> )
}
