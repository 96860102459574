import React, { useState, useEffect, useRef, useContext, FC } from "react"
import Styles from "../styles/post.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { navigate, Link } from "gatsby"
import Tag from "../components/atoms/Tag";
import TagAddButton from "../components/atoms/TagAddButton";
import Video from "../components/Video";
import MyImage from "../components/ImageComponent";
import CommentItem from "../components/CommentItem";
import CommentFormButton from "../components/atoms/CommentFormButton";
import CommentForm from "../components/CommentForm";
import VideoListManagement from "../components/VideoListManagement";
import VideoComparisonModal from "../templates/VideoComparisonModal";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import ActionSheet from "../components/ActionSheet";
import Modal from "../components/Modal";
import SelectBoxWithInputSearch from "../components/SelectBoxWithInputSearch";
import useDynamicPath from "../hooks/useDynamicPath";
import LinkIncludedText from "../components/LinkIncludedText";
import UserAvatar from "../components/UserAvatar";
import GeneralButton from "../components/atoms/GeneralButton";
import useDetectDevice from "../hooks/useDetectDevice"
import AppOpenOrMoveToStoreButton from "../components/atoms/AppOpenOrMoveToStoreButton"
import TutorialModal from "../templates/TutorialModal";
import amplitude, { logEvent } from "../Analytics";
import { connect } from "react-redux";
import { AppState, saveVideo, setCommentFormFile, setCommentFormVisible } from "../state/app";
import moment from 'moment';
import { useTeamMember } from "../hooks/useTeamMember"
import { ShareButton, SNSShareButtonList } from "../components/ShareButton"
import { ToastContext } from '../context/ToastContext';
import { PublishRangeIcon } from "../components/atoms/PublishRangeIcon"
import { PageProps } from "gatsby"
import { VideoType } from "../types/models/Media/Video";
import { isDetailPost, isImagePost, isPostNotUndefined, isVideoPost, PostDetailType, PostTimeLineType, PublishType } from "../types/models/Post";
import { PostDetailGetType } from "../types/response/Post";
import { VideoSelectAction, VideoSelectActionType } from "../types/LocalVideoAction";
import { ToastType } from "../types/models/Toast";
import { Tag as TagAlias, TagType, TagTypeType } from "../types/models/Tag"
import { SelectButtonType, SelectType } from "../types/models/SelectButton";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BACK_BUTTON_STR } from "../constant/const";
import { ArrangedCommentType, CommentType, isVideoComment } from "../types/models/Comment";
import { useRecommendPost } from "../hooks/useRecommendPost";
import { SessionStorageWrapper } from "../utils/SesseionStorageWrapper";
import MediaQuery from "react-responsive";
import client from "../apis/client";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { DownArrowIcon } from "../icons/DownArrowIcon";
import { usePostAnalysis } from "../hooks/usePostAnalysis";
import { HeartIcon } from "../icons/HeartIcon";
import { ReactionList } from "../components/ReactionList";
import { useLocalSettings } from "../hooks/useLocalSettings";
import { getAppPostLink } from "../functions/getAppPostLink";
import { fixPostToTeam, releasePostToTeam } from "../apis/post/postFix";
import { ActionSheetMenuType } from "../types/models/ActionSheetMenu";
import { useScrollToCommentByQueryString } from "../hooks/useScrollToCommentByQueryString";


type Props = {
    savedVideo: VideoType | null,
    saveVideo: ( video: VideoType ) => void,
    setCommentFormFile: ( file: File | null ) => void,
    commentFormVisible: boolean,
    setCommentFormVisible: ( bool: boolean ) => void
}

type StateType = {
    post: any
}

type LocationType<T, S> = T & S

const PostPage: FC<PageProps & Props> = ( {
    location,
    savedVideo,
    saveVideo,
    setCommentFormFile,
    commentFormVisible,
    setCommentFormVisible
} ) => {

    const timelinePost = typeof window !== "undefined" ? ( location as any )?.state?.post as ( undefined | PostTimeLineType ) : undefined
    const { objectID: postID, waitingForApplyingScroll } = useDynamicPath( location as LocationType<typeof location, StateType>, "post", "postID" )
    const [ post, setPost ] = useState<PostDetailType | PostTimeLineType | undefined>( timelinePost )
    const [ initialPostFetch, setInitialPostFetch ] = useState( false )
    const [ attachedTagList, setAttachedTagList ] = useState<TagType[]>( [] )
    const [ attachedMyTagList, setAttachedMyTagList ] = useState<TagType[]>( [] )
    const [ allTagList, setAllTagList ] = useState<SelectButtonType<string>[]>( [] )
    const [ allMyTagList, setAllMyTagList ] = useState<SelectButtonType<string>[]>( [] )
    const [ videoList, setVideoList ] = useState<VideoType[]>( [] )
    const [ replyTarget, setReplyTarget ] = useState<CommentType | null>( null )
    const [ selectedThumbnailList, setSelectedThumbnailList ] = useState<string[]>( [] )
    const [ videoComparisonModalVisible, setVideoComparisonModalVisible ] = useState( false )
    const [ postActionSheetVisible, setPostActionSheetVisible ] = useState( false )
    const [ visibleTagAttachgModal, setVisibleTagAttachgModal ] = useState<TagTypeType | null>( null )
    const [ tagUpdating, setTagUpdating ] = useState( false )
    const [ screenPaddingBottom, setScreenPaddingBottom ] = useState( 0 )
    const commentFormVisibleRef = useRef( commentFormVisible )
    const RecommendPost = useRecommendPost( { post } )
    const { addToast } = useContext( ToastContext );
    const isLoggedIn = useIsLoggedIn()
    usePostAnalysis( {} )
    const localSettings = useLocalSettings( {} )
    const scrollToCommentByQueryString = useScrollToCommentByQueryString()


    const [ videoCompareTutorialVisivle, setVideoCompareTutorialVisivle ] = useState( false )
    const [ postScreenTutorialVisivle, setPostScreenTutorialVisivle ] = useState( false )

    const pageBackgroundRef = useRef<HTMLDivElement>( null )

    const teamMemberList = useTeamMember()

    const appLink = getAppPostLink( postID )
    // const androidAppDeepLink = `intent://--/post#Intent;scheme=club-cloud;package=jp.club_cloud.clubcloud;S.postPk=${postID};end;`

    const device = useDetectDevice()
    // useAutoTransitionToApp( { appLink: device.isiOS ? iOSAppDeepLinkURL : device.isAndroid ? androidAppDeepLink:""})


    const isPCRef = useRef( device.isPC )
    useEffect( () => {
        isPCRef.current = device.isPC
    }, [ device.isPC ] )

    useEffect( () => {
        post && SessionStorageWrapper.setItem( `post-${ postID }`, post )
    }, [ post ] )

    useEffect( () => {
        componentDidMount()
        if ( savedVideo ) savedVideo.file = savedVideo.file + "?savedVideo"
        window.history.scrollRestoration = "auto";
        logEvent( amplitude, "Screen-detail", { pk: postID, web: true } )
        return () => setCommentFormFile( null )
    }, [ postID ] )

    useEffect( () => {
        commentFormVisibleRef.current = commentFormVisible
        device.isPC ?
            document.addEventListener( "scroll", hideCommentForm ) :
            document.removeEventListener( "scroll", hideCommentForm )

        if ( !pageBackgroundRef.current ) return
        commentFormVisibleRef.current ?
            setTimeout( () => {
                pageBackgroundRef.current &&
                    pageBackgroundRef.current && pageBackgroundRef.current.addEventListener( "click", hideCommentForm )
            }, 500 )
            :
            pageBackgroundRef.current.removeEventListener( "click", hideCommentForm )
    }, [ commentFormVisible ] )

    useEffect( () => {
        replyTarget && setCommentFormVisible( true )
    }, [ replyTarget ] )

    const getAllTag = async () => {
        // userのteamのタグ
        const response = await client.get( `/api/tag/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } );
        const allTag: TagType[] = response.data.tag_list;
        const allMyTag: TagType[] = response.data.mytag_list;

        const attachedTagNameList = attachedTagList.map( tag => tag.name )
        const allTagList = allTag
            .map( tag => ( {
                // ...tag,
                value: tag.pk,
                label: tag.name,
                isSelected: attachedTagNameList.includes( tag.name )
            } ) )

        const attachedMyTagNameList = attachedMyTagList.map( tag => tag.name )
        const allMyTagList = allMyTag
            .map( tag => ( {
                // ...tag,
                value: tag.pk,
                label: tag.name,
                isSelected: attachedMyTagNameList.includes( tag.name )
            } ) )
        setAllTagList( allTagList )
        setAllMyTagList( allMyTagList )
    }

    useEffect( () => {
        if ( initialPostFetch ) return
        getAllTag()
    }, [ post, initialPostFetch ] )


    const componentDidMount = async () => {
        await getPostData();
        const thresholdCount = 2
        const openPostScreenCountForTutorialStr = localStorage.getItem( "openPostScreenCountForTutorial" )
        const openPostScreenCountForTutorial: number = openPostScreenCountForTutorialStr ?
            JSON.parse( openPostScreenCountForTutorialStr ) :
            0

        if ( openPostScreenCountForTutorial < thresholdCount ) {
            localStorage.setItem( "openPostScreenCountForTutorial", JSON.stringify( openPostScreenCountForTutorial + 1 ) )
        }
        else if ( openPostScreenCountForTutorial === thresholdCount ) {
            setTimeout( () => setPostScreenTutorialVisivle( true ), 1500 )
            localStorage.setItem( "openPostScreenCountForTutorial", JSON.stringify( openPostScreenCountForTutorial + 1 ) )
        }
        setCommentFormVisible( true )
    }

    const hideCommentForm = () => {
        const textArea = document.getElementById( "textarea" )
        textArea && textArea.blur()
    }

    const renderShareIconComponent = () =>
        <ShareButton
            // pageTitle="title"
            text={ post ? post.text : "" }
            pageTitle="投稿詳細" />

    const headerOption = {
        headerTitle: <AppOpenOrMoveToStoreButton appDeepLinkURL={ appLink } style={ { width: 160, margin: "auto" } } />,
        leftIcon: BACK_BUTTON_STR,
        rightIcon:
            device.isiOS ?
                <div style={ { display: "flex", alignItems: "center" } }>
                    { renderShareIconComponent() }
                </div>
                :
                renderShareIconComponent(),
        backButton: true
    }

    const compare = () => {
        setVideoComparisonModalVisible( true )
        const thresholdCount = 2
        const compareVideoCountForTutorialStr = localStorage.getItem( "compareVideoCountForTutorial" )
        const compareVideoCountForTutorial = compareVideoCountForTutorialStr ?
            JSON.parse( compareVideoCountForTutorialStr )
            :
            0
        if ( compareVideoCountForTutorial < thresholdCount ) {
            localStorage.setItem( "compareVideoCountForTutorial", JSON.stringify( compareVideoCountForTutorial + 1 ) )
        }
        else if ( thresholdCount === compareVideoCountForTutorial ) {
            setTimeout( () => setVideoCompareTutorialVisivle( true ), 1500 )
            localStorage.setItem( "compareVideoCountForTutorial", JSON.stringify( compareVideoCountForTutorial + 1 ) )
        }
    }

    const switchLike = async () => {
        if ( !isDetailPost( post ) ) return
        if ( !isLoggedIn ) {
            addToast( { text: "ログインユーザーのみ使える機能です", type: "warn" } )
            // alert( "ログインユーザーのみ使える機能です" );
            return
        }
        const prevLiked = post.is_liked
        const prevPost = { ...post, is_liked: !post.is_liked }
        setPost( prevPost )
        var qs = require( 'qs' );
        const data = qs.stringify( {
            type: post.is_liked ? "remove_like" : "like",
        } )
        await client.patch( `/api/post/${ postID }/like/`, data, {
            headers: ( {
                'Content-Type': 'application/x-www-form-urlencoded',
            } ),
        } )
            .then( res => {
            } )
            .catch( async error => {
                addToast( { text: "いいね！処理に失敗しました", type: "error" } )
                const prevPost = { ...post, is_liked: prevLiked }
                setPost( prevPost )
                console.log( "E", error )
            } )
    }


    const getPostData = async () => {
        const savedPost = SessionStorageWrapper.getItem( `post-${ postID }` )
        if ( savedPost ) setPost( savedPost )
        await client.get( `/api/post/${ postID }/`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        } )
            .then( async response => {
                const post = response.data;
                arrangeAndSetPostData( post )
                scrollToCommentByQueryString()
            } )
            .catch( error => {
                console.log( "ERROR", error.response )
                if ( !error.response ) {
                    alert( error )
                }
                if ( error.response.status === 301 ) {
                    // window.history.replaceState( '', '', `/post/${ error.response.data.uuid }/` )
                    // window.location.reload()
                    window.location.replace( `/post/${ error.response.data.uuid }/` )
                }
                if ( error.response.status === 404 ) {
                    alert( "存在しない投稿です" )
                    3 < window.history.length ? window.history.back()
                        :
                        navigate( "/" )
                    return
                }
                if ( error.response.status === 403 ) {
                    alert( "この投稿を閲覧する権限がありません" )
                    if ( isPCRef.current ) {
                        if ( !isLoggedIn && window.confirm( "ログインして閲覧しますか？" ) ) {
                            navigate( `/login?callback=${ window.location.pathname }` )
                        }
                    }
                    else {
                        confirmOpenApp()
                    }
                    return
                }
                if ( error.response.status === 401 ) {
                    alert( "この投稿を閲覧する権限がありません。" )
                    confirmOpenApp()
                    return
                }
            } );
    }

    const arrangeAndSetPostData = ( post: PostDetailGetType ) => {
        const attachedTagList = post.tag_list
        const attachedMyTagList = post.mytag_list
        const comment_list = post.comment_list.map( ( comment, index ) => {
            const sameAuthorAsPreviousComment = index === 0 ?
                false :
                comment.output_author.name === post.comment_list[ index - 1 ].output_author.name
            return ( { ...comment, sameAuthorAsPreviousComment, author: comment.output_author } )
        } )
        post.comment_list = comment_list
        setAttachedTagList( attachedTagList )
        setAttachedMyTagList( attachedMyTagList )
        setPost( post )
        extractVideoList( post )
        setInitialPostFetch( true )
    }

    const confirmOpenApp = () => {
        if ( window.confirm( "アプリで開きますか？\nアプリだと閲覧できるかもしれません" ) ) {
            window.location.href = appLink
            return
        }
        3 < window.history.length ?
            window.history.back() :
            navigate( "/" )
    }

    const extractVideoList = ( post: PostDetailType ) => {
        const commentVideoList: VideoType[] = []
        post.comment_list.forEach( comment => {
            isVideoComment( comment ) && !comment.deleted && commentVideoList.push( { file: comment.file, thumbnail: comment.thumbnail } )
        } )
        isVideoPost( post ) ?
            setVideoList( [ { file: post.file, thumbnail: post.thumbnail }, ...commentVideoList ] ) :
            setVideoList( commentVideoList )
    }

    const handleVideoSelectSwitch = ( action: VideoSelectActionType, video: VideoType ) => {
        let newSelectedThumbnailList: string[] = []
        if ( action === VideoSelectAction.SELECT ) {
            selectedThumbnailList.push( video.file )
            newSelectedThumbnailList = selectedThumbnailList
        }
        else if ( action === VideoSelectAction.RELEASE ) {
            let releaseVideoIndex = selectedThumbnailList.indexOf( video.file )
            newSelectedThumbnailList = selectedThumbnailList.filter( ( video, index ) => index !== releaseVideoIndex )
        }
        else if ( action === VideoSelectAction.SAVE ) {
            //前のsavedVideoは消す
            newSelectedThumbnailList = selectedThumbnailList.filter( file => !file.includes( "?savedVideo" ) )
            saveVideo( { thumbnail: video.thumbnail, file: video.file + "?savedVideo" } )
        }
        setSelectedThumbnailList( newSelectedThumbnailList.slice() )
    }

    const deletePost = async () => {
        if ( !isDetailPost( post ) ) return
        if ( !window.confirm( "この投稿を削除しますか？" ) ) return
        await client.delete( `/api/post/${ post.pk }/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( async res => {
                const deletedPostPkList_json = localStorage.getItem( "deletedPostPkList" )
                const deletedPostPkList = deletedPostPkList_json ? JSON.parse( deletedPostPkList_json ) : []
                deletedPostPkList.push( post.pk )
                localStorage.setItem( "deletedPostPkList", JSON.stringify( deletedPostPkList ) )
                // alert( "この投稿を削除しました" )
                addToast( { text: "この投稿を削除しました", type: ToastType.SUCCESS } )

                window.history.back()
            } )
            .catch( error => console.log( error.response ) )
    }

    const fixPostToTeamRequest = async () => {
        if ( !isDetailPost( post ) ) return
        try {
            const { data } = await fixPostToTeam( { postUuid: post.pk } )
            addToast( {
                position: "center",
                type: "success",
                text: "固定しました"
            } )
            console.log( data )
            setPost( { ...post, team_post_fix_uuid: data.team_post_fix_uuid } )
        }
        catch ( error ) {
            if ( error.response.status === 400 ) {
                addToast( {
                    position: "center",
                    type: "error",
                    text: error.response.data.non_field_errors[ 0 ]
                } )
            }
            else {
                addToast( {
                    position: "center",
                    type: "error",
                    text: "固定に失敗しました"
                } )
            }
        }
    }

    const releasePostToTeamRequest = async () => {
        if ( !isDetailPost( post ) || !post.team_post_fix_uuid ) return
        try {
            await releasePostToTeam( { fix_uuid: post?.team_post_fix_uuid } )
            addToast( {
                position: "center",
                type: "success",
                text: "固定を解除しました"
            } )
            setPost( { ...post, team_post_fix_uuid: null } )
        }
        catch ( error ) {
            if ( error.response.status === 404 ) {
                addToast( {
                    position: "center",
                    type: "error",
                    text: "固定されていません"
                } )
            }
            else {
                addToast( {
                    position: "center",
                    type: "error",
                    text: "固定の解除に失敗しました"
                } )
            }
        }
    }

    const renderPostAuthor = () => {
        if ( !isPostNotUndefined( post ) ) return
        return (

            <div className={ Styles.author_and_edit }>
                <div style={ { display: "flex", alignItems: "center" } }>
                    { post.output_author ?
                        <UserAvatar
                            userID={ post.output_author.pk }
                            userName={ post.output_author.name }
                            profileImageURL={ post.output_author.profile_image_100 } /> :
                        <UserAvatar /> }
                    <div style={ { margin: "2px 0" } }>
                        { post.output_author ?
                            <Link
                                style={ { marginBottom: 0, color: "rgba(0,0,0,0.75)", fontSize: 16 } }
                                to={ "/user_profile" }
                                state={ { userID: post.output_author.pk, userName: post.output_author.name } }>
                                { post.output_author.name }
                            </Link> :
                            <span>匿名ユーザー</span> }
                        <div className={ Styles.info_wrapper }>
                            <span className={ Styles.date }>{ `${ moment( post.created_at ).format( 'YYYY年MM月DD日' ) }` }</span>
                            { renderPublishRangeIcon() }
                        </div>
                    </div>
                </div>
                <div
                    className={ Styles.angle_down_icon }
                    onClick={ () => setPostActionSheetVisible( true ) } >
                    <DownArrowIcon />
                </div>
            </div>
        )
    }

    const renderPublishRangeIcon = () =>
        isDetailPost( post ) && <PublishRangeIcon type={ post.type } />


    const removeCommentFromScreen = ( commentPK: string ) => {
        if ( !isDetailPost( post ) ) return
        const commentList = post.comment_list.map( comment => comment.pk === commentPK ?
            { ...comment, text: "このコメントは削除されました", deleted: true } :
            comment )
        setPost( { ...post, comment_list: commentList } )
    }


    const renderAttachedTag = () =>
        attachedTagList.map( tag =>
            <Tag tag={ tag } style={ { marginLeft: 4 } } key={ tag.name } />
        )


    const renderAttachedMyTag = () =>
        attachedMyTagList.map( tag =>
            <Tag tag={ tag } style={ { marginLeft: 4 } } key={ tag.name } />
        )


    const renderInfo = () =>
        isPostNotUndefined( post ) &&
        <div className={ Styles.info_container }>
            <div className={ Styles.like_wrapper }>
                { localSettings.isLikeActive ?
                    <span className={ Styles.like_container }>
                        <div className={ Styles.heart_icon_wrapper }>
                            <HeartIcon
                                filled={ post.is_liked }
                                onClick={ switchLike }
                                className={ `${ Styles.heart_icon } ${ post.is_liked ? Styles.liked_heart_icon : "" }` }
                            />
                        </div>
                        <Link to="/like_user_list" state={ { post } }>
                            <span style={ { color: "#999", fontSize: "0.8rem" } }>いいねしたユーザー</span>
                        </Link>
                    </span> :
                    <span></span> }
                { isDetailPost( post ) &&
                    <ReactionList
                        targetModel="Post"
                        targetPk={ postID }
                        originalReactionList={ post.reactions }
                        setHideReactionList={ () => { } } /> }
            </div>
        </div>

    const renderCommentList = () =>
        isDetailPost( post ) &&
        post.comment_list.map( comment =>
            <CommentItem
                key={ comment.pk }
                comment={ comment as ArrangedCommentType }
                commentList={ post.comment_list }
                setReplyTarget={ setReplyTarget }
                handleVideoSelectSwitch={ handleVideoSelectSwitch }
                videoIsSelected={ isVideoComment( comment ) && selectedThumbnailList.includes( comment.file ) }
                removeCommentFromScreen={ removeCommentFromScreen }
                teamMemberList={ teamMemberList } />
        )

    const renderPostActionSheet = () => {
        if ( !isDetailPost( post ) ) return
        let menuList: ActionSheetMenuType[] = []
        if ( post.is_mine ) {
            menuList = [
                post.type !== PublishType.MYOWN && ( post.team_post_fix_uuid ?
                    { name: "チームのタイムラインの\n固定から外す", func: releasePostToTeamRequest } :
                    { name: "チームのタイムラインに\n固定する", func: fixPostToTeamRequest } ),
                { name: "編集", func: () => navigate( "/post_form", { state: { post } } ) },
                { name: "削除", func: () => deletePost() },
            ].filter( menu => menu ) as ActionSheetMenuType[]
        }
        else {
            menuList = [
                post.type !== PublishType.MYOWN && ( post.team_post_fix_uuid ?
                    { name: "チームのタイムラインの\n固定から外す", func: releasePostToTeamRequest } :
                    { name: "チームのタイムラインに\n固定する", func: fixPostToTeamRequest } ),
            ].filter( menu => menu ) as ActionSheetMenuType[]
        }
        return (
            <ActionSheet
                menuList={ menuList }
                visible={ postActionSheetVisible }
                hideActionSheet={ () => setPostActionSheetVisible( false ) } />
        )
    }

    const confirmChangeAnonymousSetting = () => {
        if ( !isDetailPost( post ) ) return
        if ( post.is_mine ) {
            addToast( { text: "投稿者は実名/匿名設定を変更できません\nなりすまし防止ややりとりの整合性担保のため、ご協力お願いします", type: "info" } )
            // alert(
            //     "投稿者は実名/匿名設定を変更できません\nなりすまし防止ややりとりの整合性担保のため、ご協力お願いします"
            // )
            return
        }
        if ( window.confirm(
            `このページ内全てのあなたのコメントのアカウント名を${ !
                post.comment_is_anonymous ? "非表示にしますか？" : "表示させますか？" }\n現在このページではあなたのアカウント名は${ post.comment_is_anonymous ? "非表示です" : "表示されています" }` )
        ) {
            changeAnonymousSetting()
        }
    }

    const changeAnonymousSetting = async () => {
        if ( !isDetailPost( post ) ) return
        if ( !isLoggedIn ) {
            addToast( { text: "ログインユーザーのみ使える機能です", type: "warn" } )
            // alert( "ログインユーザーのみ使える機能です" );
            return
        }
        const prevPost = { ...post, comment_is_anonymous: !post.comment_is_anonymous }
        setPost( prevPost )
        var qs = require( 'qs' );
        const data = qs.stringify( {
            // type:post.is_liked?"remove_like":"like",
        } )
        await client.patch( `/api/post/${ postID }/anonymous_setting/`, data, {
            headers: ( {
                'Content-Type': 'application/x-www-form-urlencoded',
            } ),
        } )
            .then( res => {
                arrangeAndSetPostData( res.data.post )
            } )
            .catch( async error => {
                // alert( "設定の変更に失敗しました" )
                addToast( { text: "設定の変更に失敗しました", type: "error" } )
                const prevPost = { ...post, comment_is_anonymous: !post.comment_is_anonymous }
                setPost( prevPost )
                console.log( "E", error )
            } )
    }

    const renderAnonymousInfo = () =>
        isDetailPost( post ) &&
        <div className={ Styles.anonymous_setting_container }>
            <div onClick={ () => confirmChangeAnonymousSetting() } style={ { display: "inline-block" } }>
                <span className={ post.comment_is_anonymous ? "" : Styles.anonymous_selected }>実名</span>
                <span className={ post.comment_is_anonymous ? Styles.anonymous_selected : "" }>匿名</span>
            </div>
        </div>

    const onChangeSelectButton = ( boxName: string, buttonList: SelectButtonType<string>[] ) =>
        boxName === TagAlias.TAG ?
            setAllTagList( buttonList ) :
            setAllMyTagList( buttonList )

    const attachTag = async () => {
        if ( !visibleTagAttachgModal ) return
        if ( !isPostNotUndefined( post ) ) return
        if ( !isLoggedIn ) {
            addToast( { text: "ログインユーザーのみ使える機能です", type: "warn" } )
            //  alert( "ログインユーザーのみ使える機能です" ); 
            return
        }
        if ( tagUpdating ) return
        const tagType = visibleTagAttachgModal

        const newAttachedTagList = tagType === TagAlias.TAG ?
            allTagList.filter( tag => tag.isSelected ) :
            allMyTagList.filter( tag => tag.isSelected )
        const data = new FormData()
        data.append( "type", tagType )
        newAttachedTagList.forEach( tag => data.append( "tag_list", tag.value ) )
        setTagUpdating( true )
        await client.patch( `/api/post/${ post.pk }/tag/`, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( () => {
                const tagList = tagType === TagAlias.TAG ?
                    allTagList :
                    allMyTagList
                const updatedTagList: TagType[] = tagList.filter( tag => tag.isSelected ).map( tag => ( {
                    name: tag.label as string,
                    isSelected: tag.isSelected,
                    pk: tag.value,
                    type: tagType
                } ) )
                // alert( "更新しました" )
                addToast( { text: "更新しました", type: ToastType.SUCCESS } )
                tagType === TagAlias.TAG ?
                    setAttachedTagList( updatedTagList ) :
                    setAttachedMyTagList( updatedTagList )

            } )
            .catch( error => console.warn( error ) )
        setTagUpdating( false )
        //ここで更新されたタグのみ isSelectedが追加
    }

    const onChangeCommentFormChangeLayout = ( height: number ) => setScreenPaddingBottom( height )

    const renderTagAttachModal = () =>
        visibleTagAttachgModal &&
        <Modal visible={ true } closeModal={ () => setVisibleTagAttachgModal( null ) }>
            <>
                <SelectBoxWithInputSearch
                    buttonList={ visibleTagAttachgModal === TagAlias.TAG ? allTagList : allMyTagList }
                    onChangeSelectButton={ onChangeSelectButton }
                    type={ SelectType.CHECKBOX }
                    label={ visibleTagAttachgModal === TagAlias.TAG ? "タグ" : "Myタグ" }
                    boxName={ visibleTagAttachgModal }
                    style={ { color: "#fff", backgroundColor: "rgba(0,0,0,0)" } }
                    placeholder={ visibleTagAttachgModal === TagAlias.TAG ? "タグを検索" : "Myタグを検索" }
                    defaultChecked={ true } />
                <div className={ Styles.tag_attach_button_container }>
                    <GeneralButton title="更新" style={ { padding: "8px 80px", marginTop: 48, } } loading={ tagUpdating } onClick={ () => attachTag() } />
                </div>
            </>
        </Modal>


    const renderTutorialVideoCompare = () => {
        const slideList = [
            { title: "同時再生のやり方", image: `1_tutorial_video_sync.png` },

        ]
        return (
            <TutorialModal
                slideList={ slideList }
                closeModal={ () => setVideoCompareTutorialVisivle( false ) } />
        )
    }

    const renderTutorialPostScreen = () => {
        const slideList = [
            { title: "動画比較のやり方", image: `1_tutorial_compare.png` },
            { title: "他の投稿の動画と比較するには", image: `tutorial_video_menu.png` },
            { title: "他の投稿の動画と比較するには", image: `3_tutorial_compare_another_post.png` },
            { title: "動画リストに追加されます", image: `tutorial_upper_video_list.png` },
        ]
        return (
            <TutorialModal
                slideList={ slideList }
                closeModal={ () => setPostScreenTutorialVisivle( false ) } />
        )
    }

    const changeHLSFile2Mp4 = ( videoURL: string ) => {
        if ( !videoURL ) return videoURL
        return videoURL.split( "." ).slice( -1 )[ 0 ] === "m3u8" ?
            videoURL.split( '/' ).slice( 0, -1 ).join( '/' ) + ".mp4" :
            videoURL
    }

    const renderCommentForm = () =>
        isPostNotUndefined( post ) &&
        <CommentForm
            onChangeLayout={ onChangeCommentFormChangeLayout }
            replyTarget={ replyTarget }
            getPostData={ getPostData }
            setReplyTarget={ setReplyTarget }
            commentIsAnonymous={ !!post.comment_is_anonymous }
            postIsCommentable={ !!post.commentable }
            canUploadVideo={ !!post.can_upload_video }
            postID={ post.pk } />


    if ( !post ) {
        return (
            <Layout headerOption={ headerOption }>
                <>
                    <SEO title="Post" />
                    <div style={ { justifyContent: "center", alignItems: "center", alignSelf: "stretch", flexDirection: "column", minHeight: "60vh", display: "flex" } }>
                        <ActivityIndicator />
                    </div>
                </>
            </Layout>
        )
    }

    return (
        <Layout
            headerOption={ videoComparisonModalVisible ?
                undefined :
                headerOption }
            rightColumnContent={
                // <div
                //     className={ Styles.for_PC_sns_share_container }>
                <div>
                    <SNSShareButtonList
                        // style={ { transform: "translate3d( 100%  , 0, 0)", zIndex: 2 } }
                        pageTitle="投稿詳細" text={ post ? post.text : "" } />
                    <div>
                        { RecommendPost.renderRecommendBox() }
                    </div>
                </div>
            }>
            <>
                {/* { waitingForApplyingScroll &&
                <div style={ {
                    position: "absolute",
                    top: 0, bottom: 0,
                    left: 0, right: 0,
                    backgroundColor: "#fff",
                    zIndex: 10,
                } }>

                </div> } */}
                <SEO title="Post" />
                {/* <YoutubePlayerText /> */ }
                { postScreenTutorialVisivle && renderTutorialPostScreen() }
                { videoCompareTutorialVisivle && renderTutorialVideoCompare() }
                { videoComparisonModalVisible &&
                    <VideoComparisonModal
                        video0={ changeHLSFile2Mp4( selectedThumbnailList[ 0 ] ) }
                        video1={ changeHLSFile2Mp4( selectedThumbnailList[ 1 ] ) }
                        setVideoComparisonModalVisible={ setVideoComparisonModalVisible } /> }
                <div className={ Styles.container } ref={ pageBackgroundRef }>
                    <VideoListManagement
                        videoList={ videoList }
                        savedVideo={ savedVideo }
                        selectedThumbnailList={ selectedThumbnailList }
                        handleVideoSelectSwitch={ handleVideoSelectSwitch }
                        comparing={ videoComparisonModalVisible }
                        compare={ compare } />
                    { isVideoPost( post ) &&
                        <Video
                            sync={ false }
                            index={ 0 }
                            action={ null }
                            dispatch={ () => { } }
                            forComparison={ false }
                            originalFile={ post.file }
                            thumbnail={ post.thumbnail }
                            handleVideoSelectSwitch={ handleVideoSelectSwitch }
                            videoLoadAndAutoPlay={ true }
                            videoIsSelected={ selectedThumbnailList.includes( post.file ) } /> }
                    { isImagePost( post ) &&
                        <MyImage
                            file={ post.file }
                            imageStyle={ { maxHeight: "35vh" } } /> }
                    { post && ![ 1, 2 ].includes( post.file_type ) &&
                        <div style={ { height: 8 } }></div> }
                    <div className={ Styles.content }>
                        {/* { renderInfo() } */ }
                        { post && renderPostAuthor() }
                        <div className={ Styles.text_wrapper }>
                            <LinkIncludedText teamMemberList={ teamMemberList }>{ post.text }</LinkIncludedText>
                        </div>
                        <div className={ Styles.tag_container }>
                            <div className={ Styles.tag_list }>
                                { renderAttachedTag() }
                                <TagAddButton style={ { color: Styles.main_color } } onClick={ () => setVisibleTagAttachgModal( TagAlias.TAG ) } />
                            </div>
                            { localSettings.isMyTagActive &&
                                <div className={ Styles.tag_list }>
                                    { renderAttachedMyTag() }
                                    <TagAddButton style={ { color: Styles.accent_color } } onClick={ () => setVisibleTagAttachgModal( TagAlias.MYTAG ) } />
                                </div> }
                            { renderTagAttachModal() }
                        </div>
                        { renderInfo() }
                        {/* { renderAnonymousInfo() } */ }
                        <hr className={ Styles.hr } />
                        <div className={ Styles.comment_list }>
                            { renderCommentList() }
                        </div>
                        { renderPostActionSheet() }
                        <div style={ { height: screenPaddingBottom } }></div>
                    </div>
                    <MediaQuery query="(max-width: 899px)">
                        { RecommendPost.renderRecommendBox() }
                    </MediaQuery>
                </div>
                { renderCommentForm() }
                <CommentFormButton
                    onClick={ () => setCommentFormVisible( true ) } />
            </>
        </Layout>
    )
}

const mapStateToProps = ( state: AppState ) => ( {
    savedVideo: state.app.savedVideo,
    commentFormVisible: state.app.commentFormVisible
} )

const mapDispatchToProps = {
    saveVideo,
    setCommentFormFile,
    setCommentFormVisible
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( PostPage )


