import React, { useRef, useContext, FC } from "react"
import Styles from "../styles/VideoListManagement.module.scss";
import GeneralButton from "../components/atoms/GeneralButton";
import getNewPathAvoidingCache from "../functions/getNewPathAvoidingCache"
import { ToastContext } from '../context/ToastContext';
import { VideoType } from "../types/models/Media/Video";
import { VideoSelectAction, VideoSelectActionType } from "../types/WebViewVideoAction";
import clsx from "clsx"

type Props = {
    videoList: VideoType[],
    savedVideo: VideoType | null,
    selectedThumbnailList: string[],
    compare: () => void,
    comparing: boolean,
    handleVideoSelectSwitch: ( action: VideoSelectActionType, video: VideoType ) => void
}

const VideoListManagement: FC<Props> = ( {
    videoList,
    savedVideo,
    selectedThumbnailList,
    compare,
    comparing,
    handleVideoSelectSwitch
} ) => {

    const { addToast } = useContext( ToastContext );
    const savedVideoThumbnail = useRef<HTMLImageElement>( null )

    const renderThumbnailList = () =>
        videoList.map( ( video, index ) =>
            <div className={ Styles.video_thumbnail } key={ video.file }>
                <img
                    tabIndex={ 0 } 
                    className={ selectedThumbnailList.includes( video.file ) ? Styles.selected : "" }
                    onClick={ () => onVideoListThumbnailClick( { file: video.file, thumbnail: video.thumbnail } ) }
                    onError={ ( e ) => { getNewPathAvoidingCache( e.target as HTMLImageElement ) } }
                    src={ video.thumbnail } />
            </div> )

    const onVideoListThumbnailClick = ( thisThumbnailVideo: VideoType ) => {
        const action = selectedThumbnailList.includes( thisThumbnailVideo.file ) ?
            VideoSelectAction.RELEASE :
            VideoSelectAction.SELECT
        handleVideoSelectSwitch( action, thisThumbnailVideo )
    }

    const onSavedVideoThumbnailClick = () => {
        if ( !savedVideo ) {
            addToast( {
                text: "ここには他の投稿の動画が入ります\n動画プレイヤー一番右のメニューボタンから、ここに動画を入れてください",
                type: "info",
                duration: 8000,
            } )
            // alert( "ここには他の投稿の動画が入ります\n動画プレイヤー一番右のメニューボタンから、ここに動画を入れてください" )
            return
        }
        onVideoListThumbnailClick( savedVideo )
        savedVideoThumbnail.current && savedVideoThumbnail.current.classList.toggle( Styles.selected )
    }

    const onCompareButtonClick = () => {
        [ 1, 2 ].includes( selectedThumbnailList.length ) ?
            compare() :
            addToast( {
                text: "動画を１つまたは2つ選択してください\n動画はタッチで選択できます",
                type: "info",
                duration: 8000,
            } )
        // alert( "動画を１つまたは2つ選択してください\n動画をタッチで選択できます" )
    }

    return (
        <div className={ Styles.container } style={ comparing ? { top: 0 } : {} }>
            <GeneralButton
                style={ { whiteSpace: "nowrap", padding: "8px 16px", } }
                textStyle={ { fontSize: "0.9rem" } }
                className={ [ 1, 2 ].includes( selectedThumbnailList.length ) ? Styles.active_button : Styles.inactive_button }
                onClick={ () => onCompareButtonClick() }
                title="比較" />
            <div className={ clsx(Styles.video_thumbnail, Styles.saved_video_thumbnail_wrapper) }>
                { savedVideo ?
                    <img src={ savedVideo.thumbnail }
                        ref={ savedVideoThumbnail }
                        onClick={ () => onSavedVideoThumbnailClick() } /> :
                    <img></img> }
            </div>
            <div className={ Styles.video_list_wrapper }>
                <div className={ Styles.video_list }>
                    { renderThumbnailList() }
                </div>
            </div>
        </div>
    )
}


export default VideoListManagement
