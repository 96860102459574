import React, { FC, CSSProperties } from 'react'
import { PlusIcon } from './PlusIcon'
import { SmileIcon } from './SmileIcon'
import Styles from "../styles/Icon.module.scss"
import clsx from "clsx"

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const EmojiAddIcon: FC<Props> = ( { onClick, className, style } ) =>
    <div
        className={ clsx( Styles.emoji_add_icon, className ) }
        style={ style }
        onClick={ () => onClick && onClick() }>
        <div className={Styles.tooltip}>
            リアクションする
        </div>
        <SmileIcon className={ Styles.smile_icon } />
        <PlusIcon className={ Styles.plus_icon } />
    </div>
