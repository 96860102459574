import React, { useState, useEffect, FC } from "react"
import Styles from "../styles/VideoComparisonModal.module.scss";
import Video from "../components/Video";
import { connect } from "react-redux";
import { AppState, setCommentFormVisible } from "../state/app";
import VideoSelectButton from '../components/VideoSelectButton';
import { ActionObjectType } from "../types/WebViewVideoAction";
import { CloseIcon } from "../icons/CloseIcon";

type Props = {
    video0: string,
    video1: string,
    setVideoComparisonModalVisible: ( bool: boolean ) => void,
    setCommentFormVisible: ( bool: boolean ) => void,
}

const VideoComparisonModal: FC<Props> = ( {
    video0,
    video1,
    setVideoComparisonModalVisible,
    setCommentFormVisible
} ) => {

    const [ sync, setSync ] = useState( false )
    const [ action, setAction ] = useState<ActionObjectType | null>( null )
    const [ video1__, setVideo1__ ] = useState( video1 )
    const [ video1IsLocalVideo, setVideo1IsLocalVideo ] = useState( false )
    const [ transparent, setTransparent ] = useState( false )

    const fixBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflowY = "hidden"
        document.getElementsByTagName( "body" )[ 0 ].style.overflowY = "hidden"
    }

    const releaseBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflowY = "auto"
        document.getElementsByTagName( "body" )[ 0 ].style.overflowY = "auto"
    }

    const onChangeVideoFile = async ( URL: string ) => {
        setVideo1IsLocalVideo( true )
        setVideo1__( URL )
    }

    useEffect( () => {
        fixBackground()
        putCommentFormToBottom()
        return () => {
            releaseBackground()
            putCommentFormOnFooter()
        }
    }, [] )

    const putCommentFormToBottom = () => {
        const commentFormButton = document.getElementById( "comment_form_button" )
        if ( commentFormButton ) commentFormButton.classList.add( "small_comment_form_button" )
        const commentForm = document.getElementById( "comment_form" )
        if ( commentForm ) commentForm.style.bottom = "0"
    }

    const putCommentFormOnFooter = () => {
        const commentFormButton = document.getElementById( "comment_form_button" )
        if ( commentFormButton ) commentFormButton.classList.remove( "small_comment_form_button" )
        const commentForm = document.getElementById( "comment_form" )
        if ( commentForm ) commentForm.style.removeProperty( 'bottom' )
    }

    useEffect( () => {
        setVideo1__( video1 )
    }, [ video1 ] )

    // syncの場合は、videoは自分自身では動画を更新せずに、dispatchを介して動画を動かす
    const dispatch = ( action: ActionObjectType ) => setAction( action )

    return (
        <div className={ `${ Styles.container }` } onClick={ () => setCommentFormVisible( false ) }>
            <div className={ Styles.close_icon } onClick={ () => setVideoComparisonModalVisible( false ) }>
                <CloseIcon style={ { fontSize: "1.8rem", color: "#fff" } } />
            </div>
            <div className={ `${ Styles.transparent_icon } ${ transparent && Styles.transparent }` } onClick={ () => setTransparent( !transparent ) }>
                <span>透過</span>
            </div>
            <Video
                originalFile={ video0 }
                sync={ sync }
                index={ 0 }
                action={ action }
                dispatch={ dispatch }
                transparent={ transparent }
                forComparison={ true } />
            { video1__ ?
                <Video
                    originalFile={ video1__ }
                    sync={ sync }
                    setSync={ setSync }
                    index={ 1 }
                    action={ action }
                    dispatch={ dispatch }
                    forComparison={ true }
                    isLocalFile={ video1IsLocalVideo } />
                :
                <VideoSelectButton onChangeVideoFile={ onChangeVideoFile } />
            }
        </div>
    )
}

const mapStateToProps = ( state: AppState ) => ( {
    commentFormVisible: state.app.commentFormVisible
} )

const mapDispatchToProps = {
    setCommentFormVisible
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)( VideoComparisonModal )
