import React, { FC, CSSProperties } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { GoComment} from "react-icons/go"


type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const CommentIcon: FC<Props> = ( { onClick, className, style } ) =>
    <GoComment className={ className } style={ style } onClick={ () => onClick && onClick() } />
    // <FontAwesomeIcon
    //     icon={ faComment as IconProp }
    //     className={ className } style={ style } onClick={ () => onClick && onClick() } />
