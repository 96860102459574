import { EmojiData, CustomEmoji } from "emoji-mart"
import { UserDetailType } from "./User"

export const isCustomEmoji = ( emoji: any ): emoji is CustomEmoji => !emoji.unified
export type ReactionType = EmojiType & {
    author: UserDetailType
    // emoji_name: string
    // emoji_unicode: string
    // text: string
    uuid: string
}

export type EmojiType = {
    emoji_name: string
    emoji_unicode: string
    text: string
}

export type ReactionGetType = {
    emoji: EmojiType
    authors: {
        name: string
        pk?: string
        reaction_pk: string
    }[]
}

export type ReactionTargetType = "Post" | "Comment"
