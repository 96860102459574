import React, { FC, CSSProperties } from 'react'
import { BiSmile } from "react-icons/bi"

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const SmileIcon: FC<Props> = ( { onClick, className, style } ) =>
    <BiSmile className={ className } style={ style } onClick={ () => onClick && onClick() } />
