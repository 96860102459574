import { AxiosPromise } from 'axios';
import client from './client';

type commentEmojiResponse = {
    reaction_pk: string
}

type ReactionParams = {
    target_model: string
    target_uuid: string
    emoji_unicode: string
    emoji_name: string
    text: string
}

export const addReactionComment = (
    reactionParams: ReactionParams ): AxiosPromise<commentEmojiResponse> => {
    return client.post( `api/reaction/`, reactionParams )
}

export const removeReactionComment = ( reactionPk:string ): AxiosPromise<commentEmojiResponse> => {
    return client.delete( `api/reaction/${reactionPk}/`)
}
