import React, { FC, CSSProperties } from 'react'
import { AiOutlinePaperClip } from "react-icons/ai"

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?:()=>void
}

export const ClipIcon: FC<Props> = ( { onClick, className, style } ) =>
    <AiOutlinePaperClip className={ className } style={ style } onClick={ () => onClick && onClick()}/>
