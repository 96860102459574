import React, { FC } from "react"
import Styles from "../styles/ReplyTarget.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CommentType } from "../types/models/Comment";
import { CloseIcon } from "../icons/CloseIcon";

type Props = {
    replyTargetComment: CommentType,
    removeReplyTarget: () => void
}

const ReplyTarget: FC<Props> = ( {
    replyTargetComment,
    removeReplyTarget
} ) => (
        <div className={ Styles.container }>
            <div className={ Styles.remove_target_icon }>
            <CloseIcon onClick={ () => removeReplyTarget() } />
            </div>
            <p className={ Styles.target_author }>{ replyTargetComment.output_author.name }</p>
            <p className={ Styles.target_text }>{ replyTargetComment.text }</p>
        </div>
    )


export default ReplyTarget
