import React, { useState, useEffect } from 'react'

type Props = {

}

export const usePostAnalysis = ({ } : Props ) => {
    useEffect(()=>{
        
        // alert("mount")
        // return ()=>alert("unmount")
    },[])

    return{

    }
}
