import React, { useEffect, useRef, useState, useContext, FC } from "react"
import Styles from "../styles/CommentForm.module.scss";
import ReplyTarget from "../components/ReplyTarget";
import TextareaAutosize from 'react-textarea-autosize';
import { useFileDropForDocument } from "../hooks/useFileDrop";
import LocalFile from "./LocalFile"
import { MentionModal } from "./MentionModal"
import amplitude, { logErrorEvent, logEvent } from "../Analytics";
import { ToastContext } from '../context/ToastContext';
import { connect } from "react-redux";
import { AppState, setCommentFormFile, setCommentFormTrimmedVideo, setCommentFormVisible } from "../state/app";
import { sleep } from "../functions/sleep"
import { ReplyTargetType } from "../types/models/Comment";
import { ToastPosition, ToastType } from "../types/models/Toast";
import { useSaveComment } from "../hooks/useSaveComment";
import { useApplySaveComment } from "../hooks/useApplySaveComment";
import { removeCommentText } from "../utils/commentSaveManager";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import client from "../apis/client";
import { FileIcon } from "../icons/FileIcon";
import { SendIcon } from "../icons/SendIcon";

type Props = {
    getPostData: () => void,
    postID: string,
    commentIsAnonymous: boolean,
    replyTarget: ReplyTargetType | null,
    setReplyTarget: ( replyTarget: ReplyTargetType | null ) => void,
    onChangeLayout: ( height: number ) => void,
    commentFormFile: File | null,
    setCommentFormFile: ( file: File | null ) => void,
    commentFormVisible: boolean,
    setCommentFormVisible: ( bool: boolean ) => void,
    postIsCommentable: boolean,
    canUploadVideo: boolean,
    commentFormTrimmedVideo: Blob | null,
    setCommentFormTrimmedVideo: ( file: Blob | null ) => void
}

const CommentForm: FC<Props> = ( {
    getPostData,
    postID,
    commentIsAnonymous,
    replyTarget,
    setReplyTarget,
    onChangeLayout,
    commentFormFile,
    setCommentFormFile,
    commentFormVisible,
    setCommentFormVisible,
    postIsCommentable,
    canUploadVideo,
    commentFormTrimmedVideo,
    setCommentFormTrimmedVideo
} ) => {

    const { addToast } = useContext( ToastContext );
    const [ text, setText ] = useState( "" )
    const textRef = useRef( text )
    useEffect( () => {
        textRef.current = text
    }, [ text ] )
    const [ videoDuration, setVideoDuration ] = useState( 0 )
    const [ uploading, setUploading ] = useState( false )
    const [ uploadRatio, setUploadRatio ] = useState( 0 )
    const [ isFileDialogOpen, setIsFileDialogOpen ] = useState( false );
    const fileInputRef = useRef<HTMLInputElement>( null )
    const commentFormContainerRef = useRef<HTMLDivElement>( null )
    const textareaRef = useRef<HTMLTextAreaElement>( null )
    const isFileDialogOpenRef = useRef( isFileDialogOpen )
    const isLoggedIn = useIsLoggedIn()
    useApplySaveComment( { postID, setText } )
    useSaveComment( { postID, textRef } )

    useEffect( () => {
        isFileDialogOpenRef.current = isFileDialogOpen
    }, [ isFileDialogOpen ] )

    useFileDropForDocument( setCommentFormFile )

    useEffect( () => {
        commentFormFile && setCommentFormVisible( true )
    }, [ commentFormFile ] )

    useEffect( () => {
        const textarea = document.getElementById( "textarea" );
        ( !commentFormVisible && textarea ) && textarea.blur()
    }, [ commentFormVisible ] )

    // commentFormの大きさ変化をpost画面のpaddingBottomに反映させる 300ms待つのは、mediaが反映されるのを待つため
    useEffect( () => {
        if ( !commentFormContainerRef.current ) return
        setTimeout( () =>
            changeLayout()
            , 300 )
    }, [ text, commentFormFile, replyTarget ] )

    const changeLayout = () => {
        if ( !commentFormContainerRef.current ) return
        const commentFormHeight = commentFormContainerRef.current && commentFormContainerRef.current.getBoundingClientRect().height
        onChangeLayout( commentFormHeight )
    }

    const getVideoDuration = ( duration: number ) => setVideoDuration( duration )

    const focusInputAndMoveCursor = ( cursorPosition: number ) => {
        const textarea = document.getElementById( "textarea" );
        textarea && textarea.focus()
        textarea && ( textarea as HTMLTextAreaElement ).setSelectionRange( cursorPosition, cursorPosition )
    }

    const onUpload = ( e: ProgressEvent ) => {
        const ratio = ( e.loaded / e.total )
        setUploadRatio( ratio )
    }

    const submit = async () => {
        if ( ! await validateUploadData() ) return

        const data = new FormData()
        data.append( "text", text )

        if ( replyTarget ) {
            data.append( "to_comment", replyTarget.pk )
            data.append( "post", postID )
            data.append( "is_anonymous", commentIsAnonymous as any )
        }
        else {
            data.append( "post", postID )
            data.append( "is_anonymous", commentIsAnonymous as any )
        }

        if ( commentFormFile ) {
            let fileName = 99 < commentFormFile.name.length ?
                commentFormFile.name.slice( commentFormFile.name.length - 99 ) :
                commentFormFile.name
            data.append( "file", commentFormTrimmedVideo || commentFormFile, fileName )
        }
        setUploading( true )
        await client.post( `/api/comment/`, data, {
            onUploadProgress: onUpload,
            // リクエストのキャンセルする時に使うキャンセルトークンの設定
            //   cancelToken: source.token
        } ).
            then( async () => {
                let logProperty = getLogProperty()
                logEvent( amplitude, "Action-Comment", logProperty )
                setText( "" )
                removeCommentText( {
                    postID
                } )
                setCommentFormFile( null )
                setReplyTarget( null )
                getPostData()
                addToast( { text: "送信しました", type: "success", position: "bottom" } )
            } )
            .catch( error => {
                addToast( { text: "送信に失敗しました", type: "error", position: "bottom" } )
                if ( error.response ) {
                    if ( error.response.status === 402 ) {
                        addToast( {
                            text: error.response.data.message,
                            type: "error",
                            duration: 6000
                        } )
                    }
                }
                // alert( JSON.stringify( error.response ) )
                if ( error.response.data && Array.isArray( error.response.data ) ) {
                    for ( let key in error.response.data ) {
                        error.response.data[ key ].forEach( ( e: string ) => {
                            alert( e )
                        } )
                    }
                }
                const logProperty = getLogProperty()
                logErrorEvent( amplitude, "Error-Comment", error, logProperty )
            } )
        setUploading( false )
    }

    const getLogProperty = () => {
        const file = commentFormFile
        const fileType = ( file && file.type ) ? file.type.split( "/" )[ 0 ] : "text"
        let logProperty
        if ( fileType === "video" ) {
            logProperty = { fileType, duration: videoDuration, web: true }
        }
        if ( fileType === "image" ) {
            logProperty = {
                fileType,
                screenShot: String( ( file as File ).name ).includes( 'screenshot' ),
                web: true
            }
        }
        if ( fileType === "text" ) {
            logProperty = { fileType, web: true }
        }
        return logProperty
    }

    const validateUploadData = async () => {

        if ( !postIsCommentable ) {
            addToast( { text: "この投稿にはコメントができません", type: "warn", position: "bottom" } )
            return false
        }
        if ( !isLoggedIn ) {
            addToast( { text: "ログインユーザーのみ使える機能です", type: "warn", position: "bottom" } )
            return false
        }
        if ( uploading ) {
            return false
        }
        if ( !text && !commentFormFile ) {
            addToast( { text: "テキストを入力するか、\nファイルを選択してください", type: "warn", position: "bottom" } )
            return false
        }
        if ( text.length > 1000 ) {
            addToast( { text: "テキストは1000字までです', 'コメントに文章を分けるなどしてください", type: "warn", position: "bottom" } )
            return false
        }
        if ( commentFormFile ) {
            if ( commentFormFile.size > ( 200 * 1000 * 1000 ) ) {
                addToast( { text: "動画は200メガバイトまでです', 'トリミングをするか、画質を落としてください", type: "warn", position: "bottom" } )
                return false
            }
        }

        return true
    }

    const onChangeFile = () => {
        if ( !fileInputRef.current || !fileInputRef.current.files ) return
        const file = fileInputRef.current.files[ 0 ]
        if ( !file ) return
        if ( file.name.includes( "." ) ) {
            file && setCommentFormFile( file )
            return
        }

        if ( window.confirm( "拡張子なしのこのファイルは、動画として扱われます。\nよろしいですか？" ) ) {
            file && setCommentFormFile( file )
            return
        }
        addToast( { text: "適切な拡張子なしに変換してからアップロードしてください", type: ToastType.WARN, position: ToastPosition.BOTTOM } )
        if ( fileInputRef.current ) fileInputRef.current.value = ""
    }

    const textAreaOnFocus = () => {
        if ( !postIsCommentable ) {
            addToast( { text: "この投稿にはコメントができません", type: ToastType.WARN, position: ToastPosition.BOTTOM } )
            textareaRef.current && textareaRef.current.blur()
        }
        setTimeout( () => setCommentFormVisible( true ), 300 )
    }

    return <>
        <div className={ `${ Styles.container } ${ commentFormVisible && Styles.visible }` }
            ref={ commentFormContainerRef }
            id="comment_form"
            style={ isLoggedIn ? {} : { bottom: 0 } }>
            { commentFormFile &&
                <LocalFile
                    file={ commentFormFile }
                    getVideoDuration={ getVideoDuration }
                    trimmedVideo={ commentFormTrimmedVideo }
                    setTrimmedVideo={ setCommentFormTrimmedVideo }
                    removeFile={ () => {
                        if ( fileInputRef.current ) fileInputRef.current.value = "";
                        setCommentFormFile( null )
                    } } /> }
            { uploading &&
                <div className={ Styles.progress_bar_wrapper }>
                    <progress value={ uploadRatio * 100 } max={ 100 } />
                    <span className={ Styles.progress_percentage }>{ Math.floor( uploadRatio * 100 ) }%</span>
                </div>
            }
            { replyTarget &&
                <ReplyTarget
                    replyTargetComment={ replyTarget }
                    removeReplyTarget={ () => setReplyTarget( null ) } />
            }
            <MentionModal text={ text } setText={ setText } focusInput={ focusInputAndMoveCursor } changeLayout={ () => changeLayout() } />
            <div className={ Styles.button_input_container }>
                <div className={ Styles.file_add }>
                    <FileIcon
                        className={ Styles.file_icon }
                        onClick={ async () => {
                            if ( canUploadVideo ) {
                                fileInputRef.current && fileInputRef.current.click()
                            }
                            else {
                                setIsFileDialogOpen( false )
                                addToast( {
                                    text: "動画のアップロード上限に達しているため、動画は送信できません。\nご注意ください",
                                    type: "warn",
                                    position: "bottom",
                                    onDismiss: () => {
                                        fileInputRef.current && fileInputRef.current.click()
                                        setIsFileDialogOpen( true )
                                    }
                                } )
                                await sleep( 4000 )
                                !isFileDialogOpenRef.current && fileInputRef.current && fileInputRef.current.click()
                                setIsFileDialogOpen( true )
                            }
                        } } />
                    <input
                        ref={ fileInputRef }
                        type="file"
                        onChange={ () => {
                            onChangeFile()
                            setIsFileDialogOpen( false )
                        } } />
                </div>
                <div className={ Styles.input_form }>
                    <TextareaAutosize
                        onChange={ e => setText( e.target.value ) }
                        inputRef={ textareaRef }
                        id="textarea"
                        name="text"
                        placeholder="コメントを入力"
                        onFocus={ () => textAreaOnFocus() }
                        value={ text }
                        maxRows={ 6 }
                        minRows={ 1.5 }
                        className={ Styles.textarea } />
                </div>
                <div className={ Styles.submit_button }>
                    <SendIcon className={ Styles.submit_icon } onClick={ () => submit() } />
                </div>
            </div>
        </div>
    </>;
}


const mapStateToProps = ( state: AppState ) => ( {
    commentFormFile: state.app.commentFormFile,
    commentFormTrimmedVideo: state.app.commentFormTrimmedVideo,
    commentFormVisible: state.app.commentFormVisible
} )

const mapDispatchToProps = {
    setCommentFormFile,
    setCommentFormTrimmedVideo,
    setCommentFormVisible,

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( CommentForm )
