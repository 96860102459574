import React, { useState, useEffect, useRef, useContext, FC, CSSProperties } from 'react';
import Styles from "../styles/ShareIcon.module.scss"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useClickAway } from "../hooks/useClickAway"
import clsx from "clsx"
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LineShareButton,
    LineIcon
} from 'react-share'
import { ToastContext } from '../context/ToastContext';
import { ClipIcon } from '../icons/ClipIcon';
import { ShareIcon } from '../icons/ShareIcon';

type Props = {
    pageTitle: string,
    text: string
}

export const ShareButton: FC<Props> = ( {
    pageTitle,
    text
} ) => {

    const [ SNSShareButtonListVisible, setSNSShareButtonListVisible ] = useState( false );
    const clickExceptRef = useRef<HTMLDivElement>( null )

    useClickAway( {
        clickExceptRef,
        clickAwayFunction: () => setSNSShareButtonListVisible( false )
    } )

    const displaySNSShareButtonList = () =>
        <SNSShareButtonList
            pageTitle={ pageTitle }
            text={ text }
            setSNSShareButtonListVisible={ setSNSShareButtonListVisible } />

    const showMenu = () => setSNSShareButtonListVisible( true )

    return (
        <div
            className={ Styles.container }
            ref={ clickExceptRef }>
            <ShareIcon
                className={ Styles.share_icon }
                onClick={ showMenu } />
            { SNSShareButtonListVisible && displaySNSShareButtonList() }
        </div> )
}

type SNSShareButtonListProps = {
    pageTitle: string,
    text: string,
    style?: CSSProperties,
    setSNSShareButtonListVisible?: ( bool: boolean ) => void
}

export const SNSShareButtonList: FC<SNSShareButtonListProps> = ( {
    pageTitle,
    text,
    style,
    setSNSShareButtonListVisible
} ) => {

    const { addToast } = useContext( ToastContext );
    const [ canUseWebShareAPI, setCanUseWebShareAPI ] = useState( true );
    const copyRef = useRef<HTMLDivElement>(null)

    useEffect( () => {
        setCanUseWebShareAPI( !!navigator.share )
    }, [] )

    const URL = window.location.href
    const title = `ClubCloud|${ pageTitle }`
    const size = 32

    const shareWithWebShareAPI = async () => {
        const textForShare = 50 < text.length ?
            text.slice( 0, 49 ) + "…" :
            text
        await navigator.share( {
            title: `ClubCloud|${ pageTitle }`,
            text: textForShare,
            url: window.location.href,
        } )
            .then( () => addToast( {
                text: "完了しました",
                type: "success"
            } ) )
            .catch( error => alert( `エラーが発生しました: ${ error }` ) )
        console.log("share end")
    };

    return (
        <div className={ Styles.sns_button_list }
            style={ style }
            onClick={ () => setSNSShareButtonListVisible && setSNSShareButtonListVisible( false ) }>
            { canUseWebShareAPI ?
                <div className={ Styles.icon_container_wrapper }>
                    <div
                        className={ Styles.icon_container }
                        onClick={ shareWithWebShareAPI }>
                        <img src="/upload.svg" className={ Styles.icon } style={ { transform: "translate3d(0, -1px ,0)" } } />
                    </div>
                    <span className={ Styles.under_icon_text }>SNS等で共有</span>
                </div>
                :
                <>
                    <FacebookShareButton
                        className={ Styles.sns_icon }
                        hashtag={ "#ClubCloud" }
                        quote={ text }
                        url={ URL }>
                        <FacebookIcon size={ size } round />
                    </FacebookShareButton>
                    <TwitterShareButton
                        className={ Styles.sns_icon }
                        url={ URL }
                        title={ title }
                        via={ "" } //Twitterのアカウント名
                        hashtags={ [ "ClubCloud", "スポーツ", ] }>
                        <TwitterIcon size={ size } round />
                    </TwitterShareButton>
                    <LineShareButton
                        className={ Styles.sns_icon }
                        url={ URL }
                        title={ title }>
                        <LineIcon size={ size } round />
                    </LineShareButton>
                </> }
            <CopyToClipboard
                text={ URL }
                onCopy={ () => addToast( {
                    text: "URLをコピーしました",
                    type: "success",
                } ) }
                >
                <div tabIndex={ 0 } 
                    ref={ copyRef }
                    onKeyPress={(e)=>{
                    e.preventDefault()
                    copyRef.current?.click()
                }}  className={ clsx( Styles.icon_container_wrapper, !canUseWebShareAPI && Styles.no_web_share_icon_container_wrapper ) }>
                    <div className={ Styles.icon_container }>
                        <ClipIcon
                            className={ Styles.icon }/>
                    </div>
                    <span className={ Styles.under_icon_text }>URLコピー</span>
                </div>
            </CopyToClipboard>
        </div> )
}
