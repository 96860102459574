import React, { FC, useState } from 'react'
import Styles from "../styles/Comment.module.scss"
import { CommentType, isImageComment, isVideoComment } from '../types/models/Comment'
import moment from "moment"
import clsx from "clsx"
import { CommentUserAvator } from './CommentUserAvator'
import { OutputAuthorType, UserType } from '../types/models/User'
import { ToComment } from './ToComment'
import Video from './Video'
import MyImage from "./ImageComponent";
import { VideoSelectActionType } from '../types/WebViewVideoAction'
import { VideoType } from '../types/models/Media/Video'
import { ReactionList } from './ReactionList'
import useLongPress from '../hooks/useLongPress'
import { useLocalSettings } from '../hooks/useLocalSettings'
import { useIsBigScreen } from '../hooks/useIsBigScreen'
import LinkIncludedText from './LinkIncludedText'
import { VideoThumbnail } from './VideoThumbnail'
import { VideoModal } from './VideoModal'


type Props = {
    comment: CommentType
    toCommentAuthor?: OutputAuthorType
    isSerialComment: boolean
    hasFile: boolean
    displayUserAvator: boolean
    scrollToComment: ( { commentPk, blink }: { commentPk: string, blink: boolean } ) => void
    handleVideoSelectSwitch: ( action: VideoSelectActionType, video: VideoType ) => void
    showThumbnailInitially: boolean
    showVideo: () => void
    videoIsSelected: boolean
    showActionSheet: () => void
    teamMemberList: UserType[]
}

export const OthersComment: FC<Props> = ( {
    comment, toCommentAuthor, hasFile, displayUserAvator, scrollToComment, handleVideoSelectSwitch,
    showThumbnailInitially, teamMemberList,
    showVideo, isSerialComment,
    videoIsSelected, showActionSheet } ) => {

    const [ videoModalVisible, setVideoModalVisible ] = useState( false );
    const [ hideReactionList, setHideReactionList ] = useState( comment.reactions.length === 0 );
    const showActionSheetOnLongPress = useLongPress( showActionSheet, 1000 )
    const localSettings = useLocalSettings( {} )
    const isBigScreen = useIsBigScreen()

    const renderDateTime = () =>
        <div className={ clsx( Styles.date_container, isSerialComment && Styles.serial_comment_date_container ) }>
            <span className={ Styles.date }>
                { `${ moment( comment.created_at ).format( 'Y/M/D/ H:mm' ) }` }
            </span>
        </div>

    return (
        <div className={ clsx( Styles.container_wrapper, localSettings.commentDateTimeVisible && Styles.container_wrapper_with_date ) }>
            { !isBigScreen && localSettings.commentDateTimeVisible &&
                renderDateTime() }
            <div className={ clsx( Styles.container, hasFile && Styles.file_comment_container ) }>
                { displayUserAvator &&
                    <p>
                        <span style={ {
                            display: "inline-block",
                            marginBottom: 2,
                            fontSize: ".7rem",
                            fontWeight: "bold",
                            wordBreak: "keep-all",
                            color: "rgba(0,0,0,.75)"
                        } }>
                            { comment.output_author.name }
                        </span>
                    </p> }
                <div style={ { display: "flex" } }>
                    { displayUserAvator &&
                        <CommentUserAvator author={ comment.output_author } /> }
                    <div className={ clsx( Styles.comment_with_info, isSerialComment && Styles.serial_comment_with_info ) }>
                        <div
                            { ...showActionSheetOnLongPress }
                            id={ `comment-${ comment.pk }` }
                            onContextMenu={ ( e ) => { e.preventDefault(); showActionSheet() } }
                            className={ Styles.comment_main }>
                            { comment.to_comment_object && toCommentAuthor &&
                                <ToComment
                                    onMyComment={ false }
                                    toComment={ comment.to_comment_object }
                                    toCommentAuthor={ toCommentAuthor }
                                    scrollToComment={ scrollToComment } /> }
                            <div className={ Styles.file_and_text }>
                                { isVideoComment( comment ) &&
                                    <div className={ Styles.file_wrapper }>
                                        <VideoThumbnail
                                            onClick={ () => setVideoModalVisible( true ) }
                                            isSelected={ videoIsSelected }
                                            thumbnail={ comment.thumbnail }
                                            file={ comment.file }
                                            videoDuration={ comment.video_duration } />
                                        <VideoModal
                                            modalVisible={ videoModalVisible }
                                            setModalVisible={ setVideoModalVisible }
                                            file={ comment.file }
                                            thumbnail={ comment.thumbnail }
                                            handleVideoSelectSwitch={ handleVideoSelectSwitch }
                                            videoIsSelected={ videoIsSelected } />
                                    </div> }
                                { isImageComment( comment ) &&
                                    <div className={ Styles.file_wrapper }>
                                        <MyImage
                                            file={ comment.file } />
                                    </div> }
                                <p className={ Styles.comment_text }>
                                    <LinkIncludedText
                                        teamMemberList={ teamMemberList }>
                                        { comment.text }
                                    </LinkIncludedText>
                                </p>
                            </div>
                            { isBigScreen && localSettings.commentDateTimeVisible &&
                                renderDateTime() }
                        </div>
                        <div className={ clsx( Styles.reaction_container, hideReactionList && Styles.hidden_reaction_container ) }>
                            <ReactionList
                                originalReactionList={ comment.reactions }
                                targetModel="Comment"
                                targetPk={ comment.pk }
                                setHideReactionList={ setHideReactionList } />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
