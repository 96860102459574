import { detectDevice } from '../functions/detectDevice'
import { sleep } from '../functions/sleep'


export const useScrollToCommentByQueryString = () => {

    const scroll = async () => {
        await sleep( 100 )
        const commentPk = getCommentPk()
        commentPk && scrollToComment( {
            commentPk,
            blink: true
        } )
        const url = new URL( location.href );
        url.searchParams.delete( 'comment' );
        window.history.replaceState( '', '', `${ url.pathname }${ url.search }` )
    }

    const getCommentPk = () => {
        const commentPk = new URLSearchParams( window.location.search ).get( "comment" )
        return commentPk
    }

    const scrollToComment = ( {
        commentPk,
        blink = false
    }: { commentPk: string, blink?: boolean } ) => {
        const toCommentObject = document.getElementById( `comment-${ commentPk }` )
        const toCommentY = toCommentObject ?
            toCommentObject.getBoundingClientRect().top :
            0
        const toCommentHeight = toCommentObject ?
            toCommentObject.getBoundingClientRect().height :
            0
        const doc = document.getElementsByTagName( "body" )[ 0 ]
        const currentScroll = window.pageYOffset
        const { isAndroid } = detectDevice()
        const scrollObject = isAndroid ?
            // doc :
            window :
            window
        const scrollAmount = isAndroid ?
            currentScroll + toCommentY - doc.getBoundingClientRect().height / 2 + toCommentHeight / 2 :
            // currentScroll + toCommentY - window.innerHeight / 2 + toCommentHeight / 2:
            currentScroll + toCommentY - window.innerHeight / 2 + toCommentHeight / 2
        scrollObject.scrollTo( {
            top: scrollAmount,
            behavior: "smooth"
        } );
        if ( !blink ) return
        toCommentObject && toCommentObject.classList.add( "blink" )
        setTimeout( () => {
            toCommentObject && toCommentObject.classList.remove( "blink" )
        }, 3000 )
    }

    return scroll
}
